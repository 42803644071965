var CryptoJS = require("crypto-js");
export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const isArray = function (a) {
  return !!a && a.constructor === Array;
};

export const truncateWords = (words, maxChars) => {
  var maxLength = maxChars || 6; // maximum number of characters to extract

  //trim the string to the maximum length
  var trimmedString = words.substr(0, maxLength);

  //re-trim if we are in the middle of a word
  if (trimmedString.lastIndexOf(" ") !== words.lastIndexOf(" "))
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
  return trimmedString;
};

export const CryptoJSAesJson = {
  stringify: function (cipherParams) {
    var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j);
  },
  parse: function (jsonStr) {
    var j = JSON.parse(jsonStr);
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct),
    });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  },
};

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

var _st = function (z, g) {
  return "" + (g !== "" ? "[" : "") + z + (g !== "" ? "]" : "");
};

export const urlFromObject = function (params, skipobjects, prefix) {
  if (skipobjects === void 0) {
    skipobjects = false;
  }
  if (prefix === void 0) {
    prefix = "";
  }
  var result = "";
  if (typeof params != "object") {
    return prefix + "=" + encodeURIComponent(params) + "&";
  }
  for (var param in params) {
    var c = "" + prefix + _st(param, prefix);
    if (isObject(params[param]) && !skipobjects) {
      result += urlFromObject(params[param], false, "" + c);
    } else if (Array.isArray(params[param]) && !skipobjects) {
      params[param].forEach((item, ind) => {
        result += urlFromObject(item, false, c + "[" + ind + "]");
      });
    } else {
      result += c + "=" + encodeURIComponent(params[param]) + "&";
    }
  }
  return result;
};

export const paymentLinkGenerator = function (id) {
  console.log(id)
};

export const extractFirebaseError = (code) => {
  if (code === "auth/wrong-password") {
    return "Incorrect password";
  } else if (code === "auth/invalid-password") {
    return "The provided value for the password user property is invalid. It must be a string with at least six characters";
  } else if (code === "auth/user-not-found") {
    return "There is no existing user record corresponding to the provided identifier.";
  } else if (code === "auth/invalid-email") {
    return "The provided value for the email user property is invalid. It must be a string email address.";
  } else if (code === "auth/internal-error") {
    return "The Authentication server encountered an unexpected error while trying to process the request, try again.";
  } else if (code === "auth/email-already-exists" || code === "email_used") {
    return "The provided email is already in use by an existing user.";
  } else if (code === "auth/weak-password") {
    return "Weak password, password must be at lease 6 in character";
  } else if (code === "ERR_NETWORK") {
    return "Network error, try again.";
  } else {
    return "Something went wrong";
  }
};

export const nairaFormart = (value) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 0,
  }).format(value);
};

/////////////////////////////////////////////////////////////////////
// Change hex color into RGB /////////////////////////////////////////////////////////////////////
export const getRGBColor = (hex) => {
  let color = hex.replace(/#/g, "");
  // rgb values
  var r = parseInt(color.substr(0, 2), 16);
  var g = parseInt(color.substr(2, 2), 16);
  var b = parseInt(color.substr(4, 2), 16);

  return `${r}, ${g}, ${b}`;
};

/////////////////////////////////////////////////////////////////////
// Determine the accessible color of text
/////////////////////////////////////////////////////////////////////
export const getAccessibleColor = (hex) => {
  let color = hex.replace(/#/g, "");
  // rgb values
  var r = parseInt(color.substr(0, 2), 16);
  var g = parseInt(color.substr(2, 2), 16);
  var b = parseInt(color.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000000" : "#FFFFFF";
};

export const calculateAge = (birthday) => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
