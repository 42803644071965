import UnverifiedScreen from "./params/unverified.screen";
import PropertyListScreen from "./params/property-list.screen";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/user.context";
import { useSearchParams } from "react-router-dom";
import PublishPropertyScreen from "./params/publish-property.screen";

const MyPropertiesScreen = () => {

    const { user } = useContext(UserContext);
    const { verify_user } = user;

    const [renderComponent, setRenderComponent] = useState(null);
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const action =searchParams.get('action');

        switch (action){
            case null:
                document.title = 'My Properties';
                setRenderComponent(<PropertyListScreen />);
                break;
            case 'add-new':
                document.title = 'Upload Property';
                setRenderComponent(<PublishPropertyScreen />);
                break;
            default:
                document.title = 'My Properties';
                setRenderComponent(<PropertyListScreen />)
                break;
        }
    }, [setRenderComponent, searchParams]);

    return (
        <>
        {  verify_user === 0 ?
            <UnverifiedScreen />
            :
            renderComponent
        }
        </>
    );
}

export default MyPropertiesScreen;