import React from "react";
import PropTypes from "prop-types";

export default function NewSwitchRoleBox({
  title,
  description,
  isActive,
  onClick,
  children,
}) {
  return (
    <button onClick={onClick}>
      <div
        className={`w-[186px] h-[186px] px-4 py-8 rounded-md ${
          isActive ? `bg-secondary` : `bg-white border border-stone-400 `
        } drop-shadow-md flex flex-col items-start justify-center`}
      >
        {children}
        <h2
          className={`${
            isActive ? `text-white` : `text-black`
          } text-md font-semibold`}
        >
          {title}
        </h2>
        <p
          className={`${
            isActive ? `text-white` : `text-black text-opacity-50`
          } text-sm text-start`}
        >
          {description}
        </p>
      </div>
    </button>
  );
}

NewSwitchRoleBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
