import PropTypes from "prop-types";
import React from "react";

const dotWrapStyle = {
  position: "absolute",
  bottom: "10px",
  left: "50%",
  transform: "translateX(-50%)",
};

const dotItemStyle = {
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  margin: "0 5px",
  display: "inline-block",
  cursor: "pointer",
};

const Dots = ({ length, activeIndex, setActiveIndex }) => {
  return (
    <div style={dotWrapStyle} role="tablist" aria-label="Carousel Navigation">
      {new Array(length).fill("").map((_, i) => (
        <span
          onClick={() => setActiveIndex(i)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setActiveIndex(i);
            }
          }}
          key={i}
          role="tab"
          tabIndex={0}
          aria-selected={i === activeIndex}
          style={{
            ...dotItemStyle,
            background: i === activeIndex ? "#FFFFFF" : "#A3A3A3",
            transform: `scale(${i === activeIndex ? 1.3 : 1})`,
          }}
        ></span>
      ))}
    </div>
  );
};

Dots.propTypes = {
  length: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
};

export default Dots;
