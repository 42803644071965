import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { runMultiBatchRequest } from "../../api/wordpress";
import PageLoader from "../../components/loaders/page-loader.component";
import { isArray, urlFromObject } from "../../utils/functions";
import NoScheduleScreen from "./components/no-schedule-screen";
import ScheduleList from "./components/schedule-list";

export default function SchedulesScreen() {
  useEffect(() => {
    document.title = "Live Scheduling";
  });

  const [queryParams] = useState({ per_page: 12, page: 1 });
  const [scheduleList, setScheduleList] = useState([]);
  const [loader, setLoader] = useState(true);
  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const email = JSON.parse(localStorage.getItem("user"))?.user?.user_email;

  const requestHistory = async () => {
    const filterObj = {
      schedule_created_by: encodeURI(email),
      schedule_property_owner: encodeURI(email),
    };
    const newQueryParams = { ...queryParams, ...filterObj };
    setLoader(true);
    await runMultiBatchRequest(token, [
      {
        path: "/live-schedule?" + urlFromObject(newQueryParams),
        method: "get",
        data: { meta_value: email, meta_key: "schedule_created_by" },
      },
    ]).then(
      axios.spread((...responses) => {
        responses.forEach((response) => {
          let arr = [];
          if (!isArray(response.data)) {
            if (Number.isInteger(Number(Object.keys(response.data)[0]))) {
              arr = Object.keys(response.data).map((key) => {
                return response.data[key];
              });
            } else {
              arr[0] = response.data;
            }
          } else {
            arr = response.data;
          }

          setScheduleList(arr);
          setLoader(false);
        });
      })
    );
  };

  useEffect(() => {
    requestHistory().catch((e) => {
      console.log(e);
    });
  }, []);
  return (
    <>
      {loader ? (
        <PageLoader size="150px" className="p-10 w-full flex justify-center" />
      ) : (
        <>
          {scheduleList.length === 0 ? (
            <NoScheduleScreen />
          ) : (
            <div className="w-full">
              <ScheduleList
                data={scheduleList}
                setLoader={setLoader}
                requestHistory={() => requestHistory()}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
