import PropTypes from "prop-types";
import PostRequestCard from "../../components/card-items/post-request-card-item/post-request-card-item.component";

const SinglePostRequest = ({ data, setSharingSlug }) => {
  return (
    <div className="md:w-[60%]">
      <PostRequestCard
        lightmode={true}
        data={data}
        setSharingDialog={setSharingSlug}
      />
    </div>
  );
};

SinglePostRequest.propTypes = {
  data: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    property_type: PropTypes.string.isRequired,
    acf: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      property_type: PropTypes.string,
      budget: PropTypes.number,
    }),
    author: PropTypes.shape({
      profile_photo: PropTypes.string,
      full_name: PropTypes.string,
      display_name: PropTypes.string,
    }),
    content: PropTypes.string,
    modified: PropTypes.string,
  }),
  setSharingSlug: PropTypes.func,
};

export default SinglePostRequest;
