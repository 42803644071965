import React from "react";
import PropTypes from "prop-types";

const Step = ({ numberOfStep, currentStep }) => {
  return (
    <div className="flex items-center">
      {Array(numberOfStep ?? 1)
        .fill()
        .map((_, idx) => (
          <React.Fragment key={idx}>
            <div
              className={`flex items-center ${
                currentStep === idx + 1 ? `text-white` : `text-black`
              } relative`}
            >
              <div
                className={`${
                  currentStep === idx + 1 ? `bg-primary` : ``
                } rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2  ${
                  idx + 1 > currentStep ? `border-secondary` : `border-primary`
                } text-center`}
              >
                <h4>{idx + 1}</h4>
              </div>
            </div>
            {idx + 1 < numberOfStep && (
              <div
                className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                  idx + 1 > currentStep ? `border-secondary` : `border-primary`
                } `}
              ></div>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

Step.propTypes = {
  numberOfStep: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Step;
