import {ReactComponent as MessageSvg} from '../../../assets/vectors/message-text.svg';

const NoMessageScreen = () => {
    return(
        <div className='flex flex-col justify-center items-center h-[80vh] gap-4 w-full'>
            <MessageSvg className="h-[200px] w-[200px]" />
            <h4 className="font-semibold text-lg text-center header-font" >No Messages yet!</h4>
            <p className="text-center text-sm text-strong-100 font-light">Explore Properties & Post Requests, Get in touch with other users</p>
          
        </div>
    );
}

export default NoMessageScreen;