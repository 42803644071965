import { Outlet, useLocation } from "react-router-dom";
import { ReactComponent as BadgeSvg } from '../../assets/vectors/Property-34.svg';
import { ReactComponent as EditSvg } from '../../assets/vectors/user-edit-1.svg';
import { ReactComponent as KeySvg } from '../../assets/vectors/key.svg';
import AccountMenu from "../../components/menus/account-menu/menu.component";

const menuItemList = [
    {   
        title : 'Edit Profile',
        icon : <EditSvg className="inline-block mr-3" />,
        path : ''
    },
    {
        title : 'Verify Identity',
        icon : <BadgeSvg className="inline-block mr-3" />,
        path : '/?action=verify_account'
    },
    {
        title : 'Password & Security',
        icon : <KeySvg className="inline-block mr-3" />,
        path : '/?action=password_security'
    },
]

const MyAccountLayout = () => {
    const location = useLocation();
    return(
        <div className="md:-m-6 flex" >
            <div className="absolute hidden md:inline-block top-[100px] border-r border-r-neutral-100 account-menu-container w-[213px] ml-[30px] ">
                <div className="mt-6">
                    <AccountMenu menuItemList={menuItemList}/>
                </div>
            </div>
            <div className={`w-full md:w-[400px] mt-6 mb-10 md:ml-[265px] overflow-auto px-2 "  ${location.pathname === '/dashboard/my-account' && "hidden md:block"}`}>
                <Outlet />
            </div>
        </div>
    );
}

export default MyAccountLayout;