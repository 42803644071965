import PropTypes from "prop-types";
import { onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authenticateWpUser, createWpUser } from "../../../api/wordpress";
import icon from "../../../assets/vectors/google.svg";
import { UserContext } from "../../../contexts/user.context";
import {
  auth,
  signInWithGooglePopup,
  signOutUser,
} from "../../../utils/firebase";
import BgModal from "../../../components/modals/background-modal.component";
import ErrorModal from "../../modals/error-modal.component";

const GoogleButtonSignIn = ({ isRegister, onCompleted }) => {
  const Location = useLocation();

  const { persistUser } = useContext(UserContext);
  const navigator = useNavigate();
  const [errorAlert, setErrorAlert] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [loader, setLoader] = useState(false);
  const [loginAction, setLoginAction] = useState(false);

  const signInUser = async () => {
    setLoginAction(true);
    try {
      const result = await signInWithGooglePopup();
      if (result) {
        console.log("User signed in:", result.user);
      }
    } catch (error) {
      if (error.code === "auth/cancelled-popup-request") {
        console.log("Popup request was canceled due to multiple requests.");
      } else {
        console.error("Error during Google Sign-In:", error);
      }
    } finally {
      setLoginAction(false);
    }
    console.log("signInUser called");
  };


  useEffect(() => {
    onAuthStateChanged(auth, async (authuser) => {
      if (authuser?.displayName && loginAction) {
        try {
          setLoader(true);
          console.log("auth",auth)
          const formFields = {
            first_name: authuser.displayName.split(" ")[0],
            last_name: authuser.displayName.split(" ")[1],
            user_email: authuser.email,
            user_pass: authuser.email,
            source: "g_auth",
          };
          await createWpUser(formFields);
          const response = await authenticateWpUser({
            username: authuser.email,
            password: authuser.email,
          });
          persistUser(response.data);
          if (onCompleted != null) {
            onCompleted();
          } else {
            navigator("/dashboard");
          }
        } catch (error) {
          console.log(error);
          try {
            const response = await authenticateWpUser({
              username: authuser.email,
              password: authuser.email,
            });
            persistUser(response.data);
            if (onCompleted != null) {
              onCompleted();
            } else {
              navigator("/dashboard");
            }
          } catch (err) {
            console.log(err);
            setErrorAlert(true);
            signOutUser(auth);
            if (Location.pathname === "/login") {
              setErrorData({
                title: "Login Failed!",
                subtitle:
                  "An error occured with your sign in, try signing in with email and password to continue.",
              });
            } else {
              setErrorData({
                title: "Sign Up Failed!",
                subtitle:
                  "An error occured with your sign up, try creating an account and signing in with email and password to continue.",
              });
            }
          }
        }
        setLoginAction(false);
        setLoader(false);
      }
    });
  });
  return (
    <>
      <div className="mb-5">
        <div
          onClick={() => signInUser()}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") signInUser();
          }}
          role="button"
          tabIndex="0"
          className="header-font cursor-pointer w-full rounded-md flex justify-center py-4 border-solid border border-stone-400 hover:border-stone-900"
        >
          <img src={icon} className="pr-2" alt="google-icon" />
          Sign {isRegister != null && isRegister ? "up" : "in"} with Google
        </div>
      </div>
      <BgModal modalState={loader} />
      <ErrorModal
        action={function () {
          setErrorAlert(false);
          navigator(0);
        }}
        title={errorData?.title}
        description={errorData?.subtitle}
        state={errorAlert}
      >
        <div className="justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            onClick={() => setErrorAlert(false)}
            type="button"
            className="close-modal w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            close
          </button>
        </div>
      </ErrorModal>
    </>
  );

};

GoogleButtonSignIn.propTypes = {
  isRegister: PropTypes.bool, 
  onCompleted: PropTypes.func, 
};
export default GoogleButtonSignIn;
