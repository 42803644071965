import PropTypes from "prop-types";
// import { motion, useMotionValue, animate } from "framer-motion";
// import React, { useState, useEffect, useRef } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "../../carousel/carousel";
// import Carousel from "framer-motion-carousel";

const ImageSlider = ({ gallery, slug }) => {
  // ...otherProps
  // const transition = {
  //   type: "spring",
  //   bounce: 0,
  // };
  // gap

  return (
    <>
      {gallery && (
        <div
          className="carousel w-full overflow-hidden cursor-grab"
          style={{ margin: "0 auto" }}
        >
          <Carousel>
            {gallery.map((img, i) => {
              return (
                <Link
                  key={i}
                  to={"../properties/" + slug}
                  className="pointer-events-none"
                >
                  <img
                    className="rounded-lg w-full pointer-events-none object-cover h-[160px]"
                    src={img.full_image_url}
                    alt={img.title}
                  />
                </Link>
              );
            })}
          </Carousel>
        </div>
        // <motion.div
        //   className="carousel w-full overflow-hidden cursor-grab"
        //   {...otherProps}
        //   whileTap={{ cursor: "grabbing" }}
        // >
        //   <motion.div
        //     drag="x"
        //     dragConstraints={{ right: 0, left: -width }}
        //     className={"carousel-inner flex " + (gap && "gap-2 ")}
        //   >
        //     {gallery.map((img, i) => {
        //       return (
        //         <motion.div
        //           key={img.id}
        //           onDragEnd={handleEndDrag}
        //           className={
        //             "carousel-inner-item min-w-full " + (gap && "max-w-[80vw] ")
        //           }
        //           style={{ x }}
        //           ref={carouselItem}
        //         >
        //           <Link
        //             to={"../properties/" + slug}
        //             className="pointer-events-none"
        //           >
        //             <img
        //               className="rounded-lg w-full pointer-events-none object-cover h-[160px]"
        //               src={img.full_image_url}
        //               alt={img.title}
        //             />
        //           </Link>
        //         </motion.div>
        //       );
        //     })}
        //   </motion.div>
        //   <div className="">
        //     {new Array(gallery.length).fill("").map((_, i) => (
        //       <span
        //         onClick={() => setIndex(i)}
        //         key={i}
        //         className="w-3 h-3 rounded-full inline-block cursor-pointer mx-1"
        //         style={{
        //           background: i === index ? "#000" : "#A3A3A3",
        //           transform: `scale(${i === index ? 1.3 : 1})`,
        //         }}
        //       ></span>
        //     ))}
        //   </div>
        // </motion.div>
      )}
    </>
  );
};

ImageSlider.propTypes = {
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      full_image_url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  slug: PropTypes.string.isRequired,
};

export default ImageSlider;
