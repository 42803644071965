import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "./routes/auth/index.route";
import DashboardLayout from "./routes/dashboard/dashboard.route";
import MyAccountLayout from "./routes/my-account/my-account.route";
import AccountChooser from "./screens/dashboard/index.screen";
import LoginScreen from "./screens/login/login.screen";
import PasswordResetScreen from "./screens/password-reset/password-reset.screen";
import PropertyListScreen from "./screens/property-list/property-list.screen";
import RegisterScreen from "./screens/register/register.screen";
import TermsConditions from "./screens/terms-conditions/terms-conditions.screen";
import VerifyEmailScreen from "./screens/verify-email/verify-email.screen";
import MyaccountScreen from "./screens/my-account/my-account.screen";
import MyPostRequestScreen from "./screens/my-post-requests/my-post-request.screen";
import MessagesScreen from "./screens/messages/messages.screen";
import PaymentHistoryScreen from "./screens/payment-history/payment-history.screen";
import SingleProperty from "./screens/single/single-property.screen";
import PostRequestsScreen from "./screens/post-requests/post-requests.screen";
import SinglePostRequest from "./screens/single/single-post-request.screen";
import NotFoundPage from "./screens/404/404.screen";
import MyPropertiesScreen from "./screens/my-properties/my-properties.screen";
import CheckOutScreen from "./screens/checkout/checkout.screen";
import SchedulesScreen from "./screens/schedules/schedules.screen";
import CompleteAccountScreen from "./screens/complete-account/index.screen";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/dashboard" element={<AccountChooser />} />
      <Route path="/complete-account" element={<CompleteAccountScreen />} />
      <Route path="/checkout" element={<CheckOutScreen />} />
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/verify-email" element={<VerifyEmailScreen />} />
        <Route path="/password-reset" element={<PasswordResetScreen />} />
      </Route>
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="property-list" element={<PropertyListScreen />} />
        <Route path="properties/:slug" element={<SingleProperty />} />
        <Route path="post-requests" element={<PostRequestsScreen />} />
        <Route path="post-requests/:slug" element={<SinglePostRequest />} />
        <Route path="my-account" element={<MyAccountLayout />}>
          <Route index element={<MyaccountScreen />} />
        </Route>
        <Route path="my-properties" element={<MyPropertiesScreen />} />
        <Route path="my-post-requests" element={<MyPostRequestScreen />} />
        <Route path="messages" element={<MessagesScreen />} />
        <Route path="schedules" element={<SchedulesScreen />} />
        <Route path="payment-history" element={<PaymentHistoryScreen />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
