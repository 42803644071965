import PropTypes from "prop-types";
import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";

const GallerySlider = ({ gallery, ...params }) => {
  const smallItemStyles = {
    borderRadius: "10px",
    cursor: "pointer",
    objectFit: "cover",
    width: "100%",
    height: "140px",
  };

  const handleKeyDown = (event, open) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      open();
    }
  };

  return (
    <Gallery>
      <div
        className={
          "grid grid-cols-5 grid-rows-2 gap-5 h-[300px] " +
          { ...params }["className"]
        }
      >
        {gallery.map((image, index) => {
          if (index === 0) {
            return (
              <Item
                original={image.full_image_url}
                thumbnail={image.full_image_url}
                width="1911"
                height="936"
                alt={image.title}
                key={image.id}
              >
                {({ ref, open }) => (
                  <div
                    className="col-span-3 row-span-2 h-[300px] w-full"
                    style={{ cursor: "pointer" }}
                    onClick={open}
                    onKeyDown={(e) => handleKeyDown(e, open)}
                    ref={ref}
                    tabIndex={0}
                    role="button"
                  >
                    <img
                      style={{ borderRadius: "10px", objectFit: "cover" }}
                      src={image.full_image_url}
                      alt={image.title}
                      className="w-full h-full"
                    />
                  </div>
                )}
              </Item>
            );
          } else if (index > 4) {
            return (
              <Item
                original={image.full_image_url}
                thumbnail={image.full_image_url}
                width="1911"
                height="936"
                alt={image.title}
                key={image.id}
              >
                {({ ref, open }) => (
                  <div
                    className="hidden"
                    style={{ cursor: "pointer", ...smallItemStyles }}
                    onClick={open}
                    onKeyDown={(e) => handleKeyDown(e, open)}
                    ref={ref}
                    tabIndex={0}
                    role="button"
                  >
                    <img
                      src={image.full_image_url}
                      alt={image.title}
                      className="w-full h-full"
                    />
                  </div>
                )}
              </Item>
            );
          } else {
            return (
              <Item
                original={image.full_image_url}
                thumbnail={image.full_image_url}
                width="1911"
                height="936"
                alt={image.title}
                key={image.id}
              >
                {({ ref, open }) => (
                  <div
                    style={{ cursor: "pointer", ...smallItemStyles }}
                    onClick={open}
                    onKeyDown={(e) => handleKeyDown(e, open)}
                    ref={ref}
                    tabIndex={0}
                    role="button"
                  >
                    <img
                      src={image.full_image_url}
                      alt={image.title}
                      className="w-full h-full"
                    />
                  </div>
                )}
              </Item>
            );
          }
        })}
      </div>
    </Gallery>
  );
};

GallerySlider.propTypes = {
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      full_image_url: PropTypes.string.isRequired,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default GallerySlider;
