import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  Timestamp,
  orderBy,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { auth, db, storage } from "../../utils/firebase";

import { useContext, useEffect, useState } from "react";
import { ReactComponent as SearchSvg } from "../../assets/vectors/icons8-search.svg";
import profileImage from "../../assets/images/user.png";
import { UserContext } from "../../contexts/user.context";
import UnVerifiedScreen from "./params/unverified.screen";
import Message from "./components/message";
import MessageForm from "./components/messageForm";
import User from "./components/user";
import NoMessageScreen from "./params/no-message.screen";
import { useLocation } from "react-router-dom";
import { CryptoJSAesJson } from "../../utils/functions";

const MessagesScreen = () => {
  const [chatUsers, setChatUsers] = useState([]);
  const [chat, setChat] = useState("");
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [img, setImg] = useState("");
  const [lastMsgsData, setLastMsgsData] = useState([]);
  var CryptoJS = require("crypto-js");
  var location = useLocation();
  var { state } = location;

  const { user } = useContext(UserContext);
  const { verify_user } = user;

  const myUserID = auth.currentUser.uid;

  const selectUser = async (user) => {
    setChat(user);

    const chatUserID = user.uid;
    const id =
      myUserID > chatUserID
        ? `${myUserID + chatUserID}`
        : `${chatUserID + myUserID}`;

    const messagesRef = collection(db, "messages", id, "chat");
    const q = query(messagesRef, orderBy("createdAt", "asc"));

    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        msgs.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setMessages(msgs);
    });

    // get last message b/w logged in user and selected user
    const docSnap = await getDoc(doc(db, "lastMsg", id));
    // if last message exists and message is from selected user
    if (docSnap.data() && docSnap.data().from !== myUserID) {
      // update last message doc, set unread to false
      await updateDoc(doc(db, "lastMsg", id), { unread: false });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);

    const chatUserId = chat.uid;

    const id =
      myUserID > chatUserId
        ? `${myUserID + chatUserId}`
        : `${chatUserId + myUserID}`;

    const users_engaged =
      myUserID > chatUserId ? [myUserID, chatUserId] : [chatUserId, myUserID];

    let url;
    if (img) {
      const imgRef = ref(
        storage,
        `images/${new Date().getTime()} - ${img.name}`
      );
      const snap = await uploadBytes(imgRef, img);
      const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      url = dlUrl;
    }

    await addDoc(collection(db, "messages", id, "chat"), {
      text,
      from: myUserID,
      to: chatUserId,
      createdAt: Timestamp.fromDate(new Date()),
      media: url || "",
    });

    await setDoc(doc(db, "lastMsg", id), {
      text,
      from: myUserID,
      to: chatUserId,
      createdAt: Timestamp.fromDate(new Date()),
      media: url || "",
      unread: true,
      users_engaged,
    });

    setText("");
    setImg("");
    setIsSending(false);
  };

  const confirmMessageSchedule = async (chatId, status) => {
    try {
      const chatUserId = chat.uid;
      const id =
        myUserID > chatUserId
          ? `${myUserID + chatUserId}`
          : `${chatUserId + myUserID}`;
      await updateDoc(doc(db, "messages", id, "chat", chatId), {
        isConfirmed: true,
        isRejected: status == "Closed" ? true : false,
      });
    } catch (e) {
      console.error(e)
    }
  };

  useEffect(() => {
    document.title = "Messages";
    const lastMsgsUserRef = collection(db, "lastMsg");
    const usersRef = collection(db, "users");

    // create query object from lastMsgs collection
    const q = query(
      lastMsgsUserRef,
      where("users_engaged", "array-contains", myUserID)
    );

    //get and parse snashot of queried object from lastMsgs query
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const chatContactsID = [];
      const chatContacts = [];
      const ObjList = []; //hold queried lastMsgs objects
      //push queried lastMsgs objects to objList
      querySnapshot.forEach((doc) => {
        ObjList.push(doc.data());
      });
      //sort based on createdAt property
      ObjList.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

      //get and push the id of chatUser from each lastMsgs item
      ObjList.forEach((item) => {
        let contactID = item.users_engaged.filter(
          (item) => item !== myUserID
        )[0];
        if (contactID !== undefined) {
          chatContactsID.push(contactID);
        }
      });

      if (chatContactsID.length > 0) {
        //query fetched and compiled ids from lastMsgs object
        const qu = query(usersRef, where("uid", "in", chatContactsID));
        const snapShot = await getDocs(qu); // get snapshot data
        //compute each snapshot data from users collections
        snapShot.forEach((doc) => {
          chatContacts.push(doc.data());
        });

        //sort chat contacts
        chatContacts.sort(function (a, b) {
          return chatContactsID.indexOf(a.uid) - chatContactsID.indexOf(b.uid);
        });
        setLastMsgsData(ObjList);
        setChatUsers(chatContacts);
      }

      if (state != null && state.hash) {
        const newChatContactemail = CryptoJS.AES.decrypt(
          state.hash,
          /* eslint-disable */
          process.env.REACT_APP_HASH_KEY,
          /* eslint-enable */
          { format: CryptoJSAesJson }
        )
          .toString(CryptoJS.enc.Utf8)
          .replaceAll('"', "");
        const queryNewChatContact = query(
          usersRef,
          where("email", "in", [newChatContactemail])
        );
        const newChatContactSnapShot = await getDocs(queryNewChatContact);
        newChatContactSnapShot.forEach((doc) => {
          selectUser(doc.data());
        });
        location.state.hash = null;
      } else if (chatContacts.length > 0) {
        selectUser(chatContacts[0]);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      {verify_user === 0 ? (
        <UnVerifiedScreen />
      ) : (
        <div className="flex flex-row -m-4">
          <aside
            className={
              "hidden md:inline-block md:absolute top-[100px] flex-col p-4 border-r border-r-neutral-100 md:w-[30%] h-[87vh] " +
              (chatUsers.length > 0 ? "" : "w-[0px] hidden")
            }
          >
            <div className="mt-1 border border-stone-300 rounded-md flex h-[40px] items-center p-4 gap-4 w-full focus:border-secondary">
              <SearchSvg className="w-[20px] h-[20px]" />
              <input
                className="w-full focus:outline-none text-stone-700 font-light header-font"
                placeholder="Search"
              />
            </div>
            <div className="mt-5 w-full overflow-y-auto max-h-[80vh]">
              {chatUsers.map((user, index) => (
                <User
                  key={user.uid}
                  chatUser={user}
                  selectUser={selectUser}
                  currentUser={myUserID}
                  chat={chat}
                  data={lastMsgsData[index]}
                />
              ))}
            </div>
          </aside>

          <div
            className={
              "chat-side md:ml-[36%] pl-[5px] md:w-[70%] " +
              (chatUsers.length > 0 ? "" : "ml-[0px] w-[100%] justify-center")
            }
          >
            {chat ? (
              <>
                <div className="chat-header w-full border-b border-b-neutral-200">
                  <div className="flex px-3 py-4">
                    <div className="w-[51px] mr-3">
                      <img
                        className=" w-[51px] h-[51px] rounded-full"
                        src={
                          chat?.profile_photo
                            ? chat.profile_photo
                            : profileImage
                        }
                        alt=""
                      />
                    </div>
                    <div className="w-[70%] flex flex-col align-center text-left pt-[5px]">
                      <div className="block">
                        <span className="header-font font-normal text-[16px] pr-2">
                          {chat.first_name + " " + chat.last_name}
                        </span>
                      </div>
                      <p className="text-neutral-400 font-light text-[12px]">
                        currently online
                      </p>
                    </div>
                  </div>
                </div>

                <div className="chat-area relative w-full p-6 overflow-y-auto h-[69vh] max-h-[69vh]">
                  <ul className="space-y-2">
                    {messages.length
                      ? messages.map((msg, i) => (
                          <Message
                            key={i}
                            message={msg}
                            myUserID={myUserID}
                            confirmMessageSchedule={confirmMessageSchedule}
                            fullName={chat.first_name + " " + chat.last_name}
                          />
                        ))
                      : null}
                  </ul>
                </div>

                <MessageForm
                  handleSubmit={handleSubmit}
                  text={text}
                  setText={setText}
                  setImg={setImg}
                  img={img}
                  isSending={isSending}
                />
              </>
            ) : (
              <NoMessageScreen />
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default MessagesScreen;
