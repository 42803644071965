import PropTypes from "prop-types";
import { Link, useResolvedPath } from "react-router-dom";

const ProfileDropdownMenu = ({ menuItemList, callback }) => {
  let match = useResolvedPath("");
  return (
    <div className="">
      <ul className="flex flex-col pl-2 text-neutral-500 text-[12px] font-light">
        {menuItemList.map(
          ({ title, icon, path, suffixIcon, action }, index) => {
            const rematch =
              path === "/dashboard" || path === "/" ? "" : `${match.pathname}`;
            return (
              <li
                key={index}
                className="svg-menu-item hover:text-secondary mb-6 last:mb-2"
              >
                {action !== undefined || callback !== undefined ? (
                  <span
                    className="flex flex-row justify-between pt-1 cursor-pointer"
                    onClick={() => {
                      if (callback !== undefined) {
                        callback();
                      } else {
                        action();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        if (callback !== undefined) {
                          callback();
                        } else {
                          action();
                        }
                      }
                    }}
                    role="button"
                    tabIndex={0}
                  >
                    <div>
                      {icon}
                      {title}
                    </div>

                    {suffixIcon}
                  </span>
                ) : (
                  <Link
                    className="flex flex-row justify-between pt-1"
                    to={`${rematch}${path}`}
                  >
                    <div>
                      {icon}
                      {title}
                    </div>
                    {suffixIcon}
                  </Link>
                )}
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

ProfileDropdownMenu.propTypes = {
  menuItemList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node,
      path: PropTypes.string, 
      suffixIcon: PropTypes.node, 
      action: PropTypes.func,
    })
  ).isRequired,
  callback: PropTypes.func,
};

export default ProfileDropdownMenu;
