import PropTypes from "prop-types";
import moreIcon from "../../../assets/vectors/more-1.svg";
import ImageDropdown from "../../dropdowns/image-dropdown/image-dropdown.component";
import AlignedImageBox2 from "../../image-boxes/aligned-image-box/aligned-image-box-2";
import ProfileDropdownMenu from "../../menus/profile-dropdown-menu/profile-dropdown-menu.component";

import { VscCopy } from "react-icons/vsc";

import { ReactComponent as Edit2Svg } from "../../../assets/vectors/edit-2.svg";
import { ReactComponent as TrashSvg } from "../../../assets/vectors/trash.svg";
import { ReactComponent as ShareSvg } from "../../../assets/vectors/share.svg";
import { nairaFormart } from "../../../utils/functions";

const MyPropertyCardItem = ({ data, sn }) => {
  const ItemList = [
    {
      title: "Edit",
      icon: <Edit2Svg className="inline-block mr-2 h-4" />,
      path: "/?action=edit&id=" + parseInt(data?.id),
    },
    {
      title: "Share",
      icon: <ShareSvg className="inline-block mr-2 pr-[1px] h-4" />,
      path: "/?action=share&id=" + parseInt(data?.id),
    },
    {
      title: "Copy payment link",
      icon: <VscCopy className="inline-block mr-2 h-4" />,
      path: "/?action=copy&id=" + parseInt(data?.id),
    },
    {
      title: "Delete",
      icon: <TrashSvg className="inline-block -ml-1 mr-1 h-4" />,
      path: "/?action=delete&id=" + parseInt(data?.id),
    },
  ];
  return (
    <>
      <div className="table-header flex font-light gap-5 mt-8 text-[14px]">
        <div className="w-[3%]">{sn}</div>
        <div className="w-[27%]">
          <AlignedImageBox2
            profileImage={data?.acf.gallery[0].full_image_url}
            text={data?.title}
            subtext={data?.property_type}
          />
        </div>
        <div className="w-[25%]">{data?.acf.address}</div>
        <div className="w-[15%]">{nairaFormart(data?.acf.price)}</div>
        {data?.acf?.occupancy_status && data?.acf?.occupancy_status === true ? (
          <div className="w-[20%] text-center">
            <span className="text-green bg-green-200 font-normal py-1 px-4 rounded-2xl text-sm">
              Occupied
            </span>
          </div>
        ) : (
          <div className="w-[20%] text-center">
            <span className="text-stone bg-stone-200 font-normal py-1 px-4 rounded-2xl text-sm">
              Unoccupied
            </span>
          </div>
        )}
        <div className="w-[10%]">
          <ImageDropdown
            panelElement={<ProfileDropdownMenu menuItemList={ItemList} />}
            image={{ src: moreIcon, size: 24 }}
            nocaretIcon={" "}
            appendClass={"-translate-x-[28%] -mt-[2px]"}
          />
        </div>
      </div>
    </>
  );
};

MyPropertyCardItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    property_type: PropTypes.string.isRequired,
    acf: PropTypes.shape({
      gallery: PropTypes.arrayOf(
        PropTypes.shape({
          full_image_url: PropTypes.string.isRequired,
        })
      ).isRequired,
      address: PropTypes.string.isRequired,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      occupancy_status: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  sn: PropTypes.number.isRequired,
};

export default MyPropertyCardItem;
