import PropTypes from "prop-types";
import React from "react";
import GenericModal from "./generic-modal.component";
import { ReactComponent as ErrorSVG } from "../../assets/vectors/error.svg";

export default function ErrorModal({
  action,
  state,
  title,
  description,
  children,
}) {
  return (
    <GenericModal
      onClick={(event) => {
        if (event.target.classList.contains("close-modal")) {
          action();
        }
      }}
      modalState={state}
    >
      <div className="bg-white p-[54px] justify-center items-center text-center">
        <button
          onClick={() => action()}
          className="close-modal py-[5px] px-3 rounded-full border border-black absolute top-3 right-3"
        >
          X
        </button>
        <div className="flex flex-col gap-5 justify-center items-center">
          <ErrorSVG className="h-[70px]" />
          {title && (
            <h3 className="text-bold text-[20px] header-font">{title}</h3>
          )}
          {description && (
            <p className="font-light text-strong-[200] text-md mb-4">
              {description}
            </p>
          )}
          {children}
        </div>
      </div>
    </GenericModal>
  );
}

ErrorModal.propTypes = {
  action: PropTypes.func.isRequired, 
  state: PropTypes.bool.isRequired, 
  title: PropTypes.string, 
  description: PropTypes.string, 
  children: PropTypes.node, 
};