import PropTypes from "prop-types";
import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  createPostRequest,
  prepareUpdatePostRequest,
  updatePostRequest,
} from "../../../api/wordpress";
import { FormFieldsContext } from "../../../contexts/form-fields.context";

// import SearchLocationInput from "../../fields/google-maps-search-input/google-maps-search-input.component";
import CurrencyInputField from "../../fields/input-field/currency-input.component";
import InputField from "../../fields/input-field/form-input.component";
import SelectField from "../../fields/select-field/form-select.component";
import BtnLoader from "../../loaders/btn-loader.component";
import PageLoader from "../../loaders/page-loader.component";
import SuccessModal from "../../modals/success-modal.component";
import ErrorTextPane from "../../text-boxes/info-panes/error-status-pane.component";

const defaultErrorObject = {
  code: "",
  status: false,
  message: "",
};

const defaultFormFields = {
  slug: false,
  budget: 0,
  expected_arrival_datetime: "",
  content: "",
  city: "",
  state: "",
  property_type: null,
};

const PublishPostRequestForm = ({ setReloadPosts, setParams }) => {
  const [params] = useSearchParams();

  // const [addressObj, setAddressObj] = useState();
  const [addressObj] = useState();
  const { propertyTypes } = useContext(FormFieldsContext);

  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const user_email = JSON.parse(localStorage.getItem("user"))?.user?.user_email;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [btnloader, setBtnLoading] = useState(false);
  const [modalState, setModalState] = useState(false);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const {
    slug,
    budget,
    expected_arrival_datetime,
    content,
    property_type,
    city,
    // state,
    // lga,
    landmark,
    street,
  } = formFields;

  const [errorObj, setLoginError] = useState(defaultErrorObject);
  const [stateOptions, setStateOptions] = useState([]);
  const [lgaOptions, setLgaOptions] = useState([]);

  useEffect(() => {
    fetchStates();
  }, []);

  const getToken = async () => {
    const response = await axios.post("https://api.qoreid.com/token", {
      clientId: "5RBTZGWB3EC3O32ILFON",
      secret: "2d70258369ca435aa2775366b350d2b7",
    });
    const accessToken = response?.data?.accessToken;
    return accessToken;
  };

  const fetchStates = async () => {
    const token = await getToken();
    const statesResponse = await axios.get(
      "https://api.qoreid.com/v1/countries/1/states",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setStateOptions(
      statesResponse?.data.map((state) => {
        return { value: `${state.name}-${state.id}`, name: state.name };
      })
    );
  };

  const fetchLgas = async (stateId) => {
    const token = await getToken();
    const lgasResponse = await axios.get(
      `https://api.qoreid.com/v1/countries/1/states/${stateId}/lgas`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLgaOptions(
      lgasResponse.data.map((lga) => {
        return { value: lga.name.trim(), name: lga.name };
      })
    );
  };

  const setDefaultFormFields = (data, propertyTypes) => {
    let tempFormFields = {};
    Object.keys(defaultFormFields).forEach((key) => {
      if (data[key] !== undefined) {
        if (key === "property_type") {
          tempFormFields[key] = propertyTypes?.find(
            (item) => item.name === data[key]
          )["value"];
        } else {
          tempFormFields[key] = data[key].replace(/<\/?[^>]+(>|$)/g, "");
        }
      } else if (data["acf"][key] !== undefined) {
        if (key === "expected_arrival_datetime") {
          let str = data["acf"][key];
          let date = str.split("/").reverse().join("-");
          tempFormFields[key] = date;
        } else {
          tempFormFields[key] = data["acf"][key];
        }
      }
      setFormFields(tempFormFields);
    });
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "state") {
      setLgaOptions([]);
      const splittedValue = value.split("-");
      value = splittedValue[0].trim();
      fetchLgas(splittedValue[1]);
    }
    setFormFields({ ...formFields, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (budget < 10000) {
      setLoginError({
        code: 500,
        message: "Price must be in the range of N10,000 above.",
        status: true,
      });
      return;
    }
    const formComponent = event.currentTarget;
    formComponent.querySelector("#create_post_request").disabled = "disabled";

    setBtnLoading(true);

    const formData = new FormData(formComponent);
    formData.append("user_email", user_email);
    // if (addressObj) {
    //   formData.append("city", addressObj["address_components"][1]["long_name"]);
    //   formData.append(
    //     "state",
    //     addressObj["address_components"][2]["long_name"]
    //   );
    //   formData.append("address", addressObj["formatted_address"]);
    // } else {
    //   formData.append("city", city);
    //   formData.append("state", state);
    // }

    try {
      if (params.get("action") === "edit") {
        formData.append("slug", slug);
        await updatePostRequest(token, formData);
      } else {
        await createPostRequest(token, formData);
      }
      setBtnLoading(false);
      setModalState(true);
    } catch (error) {
      setBtnLoading(false);
      const { code, message } = error.response.data
        ? error.response.data
        : error;

      setLoginError({
        code: code,
        message: message,
        status: true,
      });
      document
        .getElementById("dashboard-content")
        .scrollTo(0, 0, { behavior: "smooth", block: "start" });
      formComponent.querySelector("#create_post_request").disabled = false;
    }
  };

  useEffect(() => {
    if (params.get("action") === "edit") {
      setLoader(true);
      const requests = async () => {
        await prepareUpdatePostRequest(token, {
          slug: params.get("id"),
        }).then(
          axios.spread((...responses) => {
            const responseOne = responses[0];

            setDefaultFormFields(responseOne.data, propertyTypes);
            setLoader(false);
          })
        );
      };

      requests().catch((error) => {
        console.log(error);
      });
    }
  }, [params, token, propertyTypes]);

  useEffect(() => {
    console.log(addressObj);
  }, [addressObj]);

  return (
    <>
      {loader ? (
        <PageLoader size="150px" className="p-10 w-full flex justify-center" />
      ) : (
        <div className="md:w-[400px]">
          <form id="create-post-request_form" onSubmit={handleSubmit}>
            <ErrorTextPane
              status={errorObj.status}
              message={errorObj.message}
            />
            <p className="block text-md font-normal mb-6 ">
              {params.get("action") !== "edit"
                ? "Fill in the details below to post a request"
                : "Edit the details of your post request"}
            </p>

            <SelectField
              options={stateOptions}
              onChange={handleChange}
              label={"State"}
              name="state"
              required
              defaultValue={""}
            />

            {!lgaOptions.length ? (
              <></>
            ) : (
              <SelectField
                options={lgaOptions}
                onChange={handleChange}
                label={"LGA"}
                name="lga"
                required
                defaultValue={""}
                id="lgaOption"
              />
            )}

            <InputField
              label="City"
              type="text"
              name="city"
              value={city}
              required
              onChange={handleChange}
            />

            <InputField
              label="Street"
              type="text"
              name="street"
              value={street}
              required
              onChange={handleChange}
            />

            <InputField
              label="Landmark"
              type="text"
              name="landmark"
              value={landmark}
              required
              onChange={handleChange}
            />

            <SelectField
              options={propertyTypes}
              onChange={handleChange}
              label={"Choose Property Type"}
              name="property_type"
              required
              defaultValue={property_type}
            />

            <CurrencyInputField
              label="Budget"
              value={budget}
              required
              onChange={(v) => setFormFields({ ...formFields, budget: v })}
            />

            <InputField
              label="How soon do you want to move in?"
              type="date"
              name="expected_arrival_datetime"
              value={expected_arrival_datetime}
              required
              onChange={handleChange}
            />

            <InputField
              label="Add a brief desccription about you"
              type="textarea"
              name="content"
              value={content}
              required
              onChange={handleChange}
              placeholder="Feel free to talk about your occupation, likes, dislike and ideal property type."
            />

            <div className="field_wrapper mb-5">
              <button
                type="submit"
                name="submit"
                id="create_post_request"
                data-testid="create-post-request"
                className="w-full rounded-md text-white p-4 bg-primary hover:bg-blue-900 font-semibold"
              >
                {btnloader && <BtnLoader />}
                Post Request
              </button>
            </div>
          </form>
          <SuccessModal
            action={() => {
              setModalState(false);
              if (params.get("action") === "edit") {
                params.delete("action");
                params.delete("id");
                setParams(params);
                setReloadPosts(true);
              }
              if (params.get("action") === "add-new") {
                navigate("/dashboard/my-post-requests");
              }
            }}
            state={modalState}
            title="Request posted successfully"
            description="Your request has been successfully posted, soon you’ll have ruum owners reach out to you."
          />
        </div>
      )}
    </>
  );
};

PublishPostRequestForm.propTypes = {
  setReloadPosts: PropTypes.func.isRequired,
  setParams: PropTypes.func.isRequired,
};

export default PublishPostRequestForm;
