import PropTypes from "prop-types";
// import moreIcon from "../../../assets/vectors/more.svg";
import { ReactComponent as ShareSvg } from "../../../assets/vectors/share.svg";
import { ReactComponent as ChatSvg } from "../../../assets/vectors/message-text.svg";
import { ReactComponent as MarkerSvg } from "../../../assets/vectors/place_black_24dp-1.svg";
import { ReactComponent as BuildingSvg } from "../../../assets/vectors/Group-2104.svg";
import { ReactComponent as TagSvg } from "../../../assets/vectors/Frame.svg";
import { BsEye, BsShare } from "react-icons/bs";

import Moment from "react-moment";

import AlignedImageBox from "../../image-boxes/aligned-image-box/aligned-image-box";
// import ProfileDropdownMenu from "../../menus/profile-dropdown-menu/profile-dropdown-menu.component";
// import ImageDropdown from "../../dropdowns/image-dropdown/image-dropdown.component";
import SimpleIconBox from "../../image-boxes/simple-icon-box/simple-icon-box.component";
import { nairaFormart, truncateWords } from "../../../utils/functions";
import { useNavigate, useResolvedPath } from "react-router-dom";

const PostRequestCard = ({ lightmode, data, setSharingDialog }) => {
  let match = useResolvedPath("");
  let navigate = useNavigate();
  const ItemList = [
    {
      title: "View",
      icon: <BsEye className="inline-block mr-3 w-4 h-5" />,
      path: "/?action=view&id=" + data?.slug,
    },
    {
      title: "Message",
      icon: <ChatSvg className="inline-block mr-2 h-4 -ml-1" />,
      path: "/?action=chat&id=" + data?.slug,
    },
    {
      title: "Share",
      icon: <ShareSvg className="inline-block mr-2 pr-[1px] h-4" />,
      path: "/?action=share&id=" + data?.slug,
    },
  ];

  const location = data?.acf?.city + ", " + data?.acf?.state;

  return (
    <div className="flex flex-col p-3 md:p-7 shadow-sm border border-stone-100 md:border-stone-200 rounded-sm md:rounded-md gap-6">
      <div className="flex justify-between items-center">
        <AlignedImageBox
          check={true}
          profileImage={data?.author?.profile_photo}
          text={data?.author?.full_name}
          subtext={"@" + data?.author?.display_name}
        />
        {lightmode ? (
          <button
            className="flex gap-4 items-center text-sm md:text-md"
            onClick={() => {
              setSharingDialog(true);
            }}
          >
            <BsShare className="text-md md:text-xl text-secondary" />
            Share
          </button>
        ) : (
          <button
            className="flex gap-4 items-center text-sm md:text-md"
            onClick={() => {
              navigate("./?action=share&id=" + data?.slug);
            }}
          >
            <BsShare className="text-md md:text-xl text-secondary" />
            Share
          </button>
        )}
      </div>
      <p
        className="font-light text-stone-500 text-[12px] pr-3"
        dangerouslySetInnerHTML={{ __html: data?.content }}
      />
      <div className="flex gap-2 md:gap-5 text-[11px] md:text-[14px] -mb-1 md:-mb-4 ">
        {data?.acf?.city && (
          <SimpleIconBox
            key={3}
            icon={<MarkerSvg />}
            text={
              <p className="text-stone-800 -ml-1">
                {truncateWords(location, 24)}
              </p>
            }
          />
        )}
        {(data?.property_type || data?.acf?.property_type) && (
          <SimpleIconBox
            key={4}
            icon={<BuildingSvg className="h-4" />}
            text={
              <p className="text-stone-800 -ml-1">
                {data?.property_type || data?.acf?.property_type}
              </p>
            }
          />
        )}
        {data?.acf?.budget && (
          <SimpleIconBox
            key={5}
            icon={<TagSvg className="h-4" />}
            text={
              <p className="text-stone-800 -ml-1">
                {nairaFormart(data?.acf?.budget)}
              </p>
            }
          />
        )}
      </div>
      <div className="flex justify-between items-center mt-4">
        <button
          className="rounded-md md:rounded-lg text-center text-white text-xs md:text-sm md:text-[12px] py-2 md:py-3 px-8 md:py-[6px] bg-primary hover:bg-blue-900"
          onClick={() => navigate(match.pathname + ItemList[1].path)}
        >
          {" "}
          Message{" "}
        </button>
        <p>
          <Moment
            className="block  font-extralight text-[12px] text-stone-500"
            fromNow
          >
            {data?.modified}
          </Moment>
        </p>
      </div>
      {lightmode && (
        <div className="flex justify-center mb-2 ">
          <button
            className="w-[60%] rounded-md md:rounded-lg text-center text-white text-sm md:text-[18px] py-3 px-8 md:py-[10px] bg-primary hover:bg-blue-900"
            onClick={() => navigate(match.pathname + ItemList[1].path)}
          >
            {" "}
            Message ruumate{" "}
          </button>
        </div>
      )}
    </div>
  );
};

PostRequestCard.propTypes = {
  lightmode: PropTypes.bool,
  data: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    property_type: PropTypes.string.isRequired,
    acf: PropTypes.shape({
      city: PropTypes.string,
      state: PropTypes.string,
      property_type: PropTypes.string,
      budget: PropTypes.string,
    }).isRequired,
    author: PropTypes.shape({
      profile_photo: PropTypes.string,
      full_name: PropTypes.string,
      display_name: PropTypes.string,
    }).isRequired,
    content: PropTypes.string.isRequired,
    modified: PropTypes.string.isRequired,
  }).isRequired,
  setSharingDialog: PropTypes.func,
};

export default PostRequestCard;
