import PropTypes from "prop-types";
import { Link, useResolvedPath } from "react-router-dom";
import { RiArrowRightSLine } from "react-icons/ri";

const MobileProfileMenu = ({ menuItemList, callback }) => {
  let match = useResolvedPath("");
  return (
    <div className="pt-4">
      <ul className="flex flex-col m-4 text-neutral-500 text-[12px] font-light">
        {menuItemList.map(({ title, icon, path, action }, index) => {
          const rematch =
            path === "/dashboard" || path === "/" ? "" : `${match.pathname}`;
          return (
            <li
              key={index}
              className="svg-menu-item hover:text-secondary mb-3 last:mb-2"
            >
              {action && callback ? (
                <span
                  className="flex pt-1 cursor-pointer"
                  onClick={callback}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") callback();
                  }}
                  role="button"
                  tabIndex={0}
                >
                  {icon}
                  {title}
                </span>
              ) : (
                <Link
                  className="flex pt-3 bg-gray-100 p-3 flex justify-between items-center"
                  to={`${rematch}${path}`}
                >
                  <div className="flex items-center">
                    {icon}
                    {title}
                  </div>
                  <RiArrowRightSLine className="h-5 w-5" />
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};


MobileProfileMenu.propTypes = {
  menuItemList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.element, 
      path: PropTypes.string.isRequired,
      action: PropTypes.func, 
    })
  ).isRequired,
  callback: PropTypes.func, 
};

export default MobileProfileMenu;
