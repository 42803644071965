
const VerifyEmailForm =  () => {
    return (
        <>
            <form id="verify_email_form" className="font-light" type="post" action="">
                <p className="block text-md mb-12 ">Enter your email address to send verification link</p>
                <div className="field_wrapper mb-10">
                    <label htmlFor="user_email" className="block text-sm pb-2">Email Address</label>
                    <input type="email" required="required" name="user_email" id="user_email" className="w-full rounded-sm p-4 border-solid border border-stone-400 focus:outline-none focus:ring-2" />
                </div>
                <div className="field_wrapper mb-10 hidden ">
                    <label htmlFor="user_pass" className="block text-sm pb-2">Password</label>
                    <input type="password" name="user_pass" id="user_pass" className="w-full rounded-sm border-solid border border-stone-400 p-4 focus:outline-none focus:ring-2" />
                </div>
                <div className="field_wrapper">
                    <input type="submit" name="submit" id="verify_user_email" value="Send me a mail" className="w-full rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer" />
                </div>
            </form>
        </>
    );
}

export default VerifyEmailForm;