import PropertyFilterForm from "../../components/forms/property-filter/property-filter-form.component";
import PropertyCardLoop from "../../components/loops/property-card-loop/property-card-loop.component";
import { useEffect, useState } from "react";
import { getPropertyList } from "../../api/wordpress";
import PageLoader from "../../components/loaders/page-loader.component";
import { isArray } from "../../utils/functions";
import BtnLoader from "../../components/loaders/btn-loader.component";

const PropertyListScreen = () => {
  const [queryParams, setQueryParams] = useState({ per_page: 12, page: 1 });
  const [btnloader, setBtnLoader] = useState(false);
  const [maxPages, setMaxPages] = useState(0);
  const [queryChangeTrigger, setQueryChangeTrigger] = useState("");
  const [propertyList, setPropertylist] = useState([]);
  const [propertyLoader, setPropertyLoader] = useState(true);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"))?.token;

    document.title = "Property Listing";

    const propertyListRequest = async () => {
      if (queryChangeTrigger !== "pagination") setPropertyLoader(true);
      const response = await getPropertyList(token, queryParams);

      let arr = [];
      if (!isArray(response.data)) {
        if (Number.isInteger(Number(Object.keys(response.data)[0]))) {
          arr = Object.keys(response.data).map((key) => {
            return response.data[key];
          });
        } else {
          arr[0] = response.data;
        }
      } else {
        arr = response.data;
      }

      if (queryChangeTrigger === "pagination")
        setPropertylist(propertyList.concat(arr));
      else setPropertylist(arr);
      setMaxPages(response.headers["x-wp-totalpages"]);
      setPropertyLoader(false);
      setBtnLoader(false);
    };

    propertyListRequest().catch((error) => {
      console.log(error);
    });
  }, [queryParams]);

  return (
    <div className="property-list-screen md:pr-8 mb-5 flex flex-col gap-5">
      <PropertyFilterForm
        setQueryParams={setQueryParams}
        setQueryChangeTrigger={setQueryChangeTrigger}
      />
      {propertyLoader ? (
        <PageLoader size="150px" className="p-10 w-full flex justify-center" />
      ) : (
        <>
          <PropertyCardLoop propertyList={propertyList} />

          {propertyList.length > 0 && maxPages > 1 && (
            <div className="flex justify-center w-full mt-5 mb-5">
              {maxPages - 1 >= queryParams["page"] ? (
                <button
                  className="pagination bg-blue-100 header-font text-primary py-1 px-5 text-sm rounded-full hover:bg-blue-200"
                  onClick={() => {
                    setQueryChangeTrigger("pagination");
                    setQueryParams({
                      ...queryParams,
                      page: queryParams["page"] + 1,
                    });
                    setBtnLoader(true);
                  }}
                >
                  {btnloader && <BtnLoader color={true} />}
                  Load More
                </button>
              ) : (
                <>
                  {btnloader ? (
                    <button className="pagination bg-blue-100 header-font text-primary py-1 px-5 text-sm rounded-full hover:bg-blue-200">
                      {btnloader && <BtnLoader color={true} />}
                      Load More
                    </button>
                  ) : (
                    <button className="pagination bg-blue-100 header-font text-primary py-1 px-5 text-sm rounded-full hover:bg-blue-200">
                      This is the end of properties results
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PropertyListScreen;
