import logo from "../../assets/images/logoimg.png";
import { useEffect, useState } from "react";
import Step from "../../components/step/step.component";
import AccountRegister from "../../components/step/register/account-register.component";
import BiodataRegister from "../../components/step/register/biodata-register.component";
import CompletedRegister from "../../components/step/register/completed-register.component";
import RoleRegister from "../../components/step/register/role-register.component";
import image from "../../assets/images/register-bg.jpg";
import RuumiesLogo from "../../assets/images/ruumies-white-logo.svg";
import { useNavigate } from "react-router-dom";

const RegisterScreen = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const navigator = useNavigate();

  useEffect(() => {
    document.title = "Register - Rummies";
  });
  return (
    <>
      <div
        className="hidden md:inline-block md:w-1/3 h-full bg-cover bg-top"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="h-full flex flex-col justify-between px-6 py-10">
          <img src={RuumiesLogo} alt="Ruumies Logo" width={110} height={23} />
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div className="flex flex-col">
            <h4 className="text-white text-[48px] font-medium">
              Get the right ruummate in few clicks
            </h4>
            <p className="text-white text-[20px] text-opacity-80 font-bold">
              Simplified for easy access to a teeming backlog of users.
            </p>
          </div>
          {/* <div></div>
          <div></div> */}
        </div>
      </div>
      <div className="inline-block w-full md:w-2/3 h-full pt-10 overflow-auto">
        <div className="p-5 pt-0 lg:w-[500px] md:w-2/3 mx-auto">
          <div className="w-full flex flex-col justify-center">
            <div className="mb-8 flex items-center justify-center">
              <img src={logo} alt="logo" width="110" height="23" />
            </div>
            <Step numberOfStep={4} currentStep={currentStep} />
            {currentStep === 1 && (
              <AccountRegister
                onCompleted={() => setCurrentStep(currentStep + 1)}
              />
            )}
            {currentStep === 2 && (
              <BiodataRegister onCompleted={() => navigator("/dashboard")} />
            )}
            {currentStep === 3 && (
              <RoleRegister
                onCompleted={() => setCurrentStep(currentStep + 1)}
              />
            )}
            {currentStep === 4 && <CompletedRegister />}
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterScreen;
