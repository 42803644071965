import PropTypes from "prop-types";

export const SelectField = ({ label, options, ...otherProps }) => {
  const renderRadioButtons = otherProps.type === "radio";
  const renderCheckboxes = otherProps.type === "checkbox";

  return (
    <>
      <div className="field_wrapper mb-10">
        <label
          id={otherProps.name}
          htmlFor={otherProps.name}
          className="block text-sm text-gray-600 pb-3"
        >
          {label}
        </label>
        {renderRadioButtons && (
          <div className="flex">
            {options.map(({ value, name }, index) => {
              return (
                <div className="flex items-center mr-6" key={index}>
                  <input
                    id={`${otherProps.name}-${value}`}
                    onChange={otherProps.onChange}
                    {...(otherProps.defaultValue &&
                    otherProps.defaultValue === value
                      ? { defaultChecked: true }
                      : {})}
                    type="radio"
                    value={value}
                    name={otherProps.name}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label
                    htmlFor={`${otherProps.name}-${value}`}
                    className="ml-2 text-sm font-light text-gray-600 "
                  >
                    {name}
                  </label>
                </div>
              );
            })}
          </div>
        )}
        {renderCheckboxes && (
          <div className="grid grid-cols-2">
            {options.map(({ value, name }, index) => {
              return (
                <div key={index} className="flex items-center">
                  <input
                    onChange={otherProps.onChange}
                    id={`${otherProps.name}-${index}`}
                    {...(otherProps.defaultValue &&
                    otherProps.defaultValue.includes(value)
                      ? { defaultChecked: true }
                      : {})}
                    type="checkbox"
                    value={value}
                    name={`${otherProps.name}[]`}
                    className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                  />
                  <label
                    htmlFor={`${otherProps.name}-${index}`}
                    className="py-3 ml-2 w-full text-sm font-light text-gray-600 "
                  >
                    {name}
                  </label>
                </div>
              );
            })}
          </div>
        )}
        {!renderRadioButtons && !renderCheckboxes && (
          <select
            {...otherProps}
            className="bg-white border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-5 outline-1"
            style={{ outlineWidth: "1px", appearance: "none" }}
          >
            <option id={otherProps.id ? otherProps.id : ""} disabled value="">
              Choose one
            </option>
            {options.map(({ value, name }, index) => {
              return (
                <option key={index} value={value}>
                  {name}
                </option>
              );
            })}
          </select>
        )}
      </div>
    </>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,

};

export default SelectField;
