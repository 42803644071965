import PropTypes from "prop-types";
import { useState } from "react";
import ImageDropdown from "../../../components/dropdowns/image-dropdown/image-dropdown.component";
import AlignedImageBox2 from "../../../components/image-boxes/aligned-image-box/aligned-image-box-2";
import ProfileDropdownMenu from "../../../components/menus/profile-dropdown-menu/profile-dropdown-menu.component";
import { CryptoJSAesJson } from "../../../utils/functions";
import moreIcon from "../../../assets/vectors/expand-more.svg";
import { ReactComponent as CardIconSvg } from "../../../assets/vectors/card-receive.svg";
import { sendNotification } from "../../../utils/firebase";
import ChangeScheduleStepModal from "../../../components/modals/change-schedule-status-modal.component";
import { updateScheduleRequest } from "../../../api/wordpress";
import GenericModal from "../../../components/modals/generic-modal.component";
import { ReactComponent as ErrorSVG } from "../../../assets/vectors/error.svg";

export default function ScheduleCard({ data, setLoader, requestHistory }) {
  const [expand, setExpand] = useState(false);
  const [openStep, setOpenStep] = useState(false);
  const [stepToUpdate, setStepToUpdate] = useState(null);
  const [loading, setLoading] = useState(false);

  const [errorDialog, setErrorDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  var CryptoJS = require("crypto-js");

  let statusColor;
  let bgStatusColor;

  if (data.acf.schedule_status === "Pending") {
    statusColor = "#E1A100";
    bgStatusColor = "rgba(220, 164, 24, 0.2)";
  } else if (data.acf.schedule_status === "Agreed") {
    statusColor = "#00A725";
    bgStatusColor = "rgba(0, 167, 37, 0.2)";
  } else if (data.acf.schedule_status === "Closed") {
    statusColor = "#959595";
    bgStatusColor = "rgba(149, 149, 149, 0.3)";
  } else {
    statusColor = "#E1A100";
    bgStatusColor = "rgba(220, 164, 24, 0.2)";
  }

  const ItemList = [
    {
      title: "On my way",
      icon: <CardIconSvg className="inline-block mr-2 pr-[1px] h-4" />,
      suffixIcon: (
        <div
          className={`rounded-full transition duration-500 ease-in-out h-5 w-5 border-2 ${
            data.acf.schedule_notify !== "" &&
            data.acf.schedule_notify === "On my way"
              ? `bg-primary border-primary`
              : `border-secondary border-opacity-30 bg-[#E9F2FF]`
          } text-white text-center flex items-center justify-center`}
        >
          {data.acf.schedule_notify !== "" &&
            data.acf.schedule_notify === "On my way" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            )}
        </div>
      ),
      action: () => notifyPropertyOwner("On my way"),
    },
    {
      title: "Arrived room",
      icon: <CardIconSvg className="inline-block mr-2 pr-[1px] h-4" />,
      suffixIcon: (
        <div
          className={`rounded-full transition duration-500 ease-in-out h-5 w-5 border-2 ${
            data.acf.schedule_notify !== "" &&
            data.acf.schedule_notify === "Arrived room"
              ? `bg-primary border-primary`
              : `border-secondary border-opacity-30 bg-[#E9F2FF]`
          } text-white text-center flex items-center justify-center`}
        >
          {data.acf.schedule_notify !== "" &&
            data.acf.schedule_notify === "Arrived room" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            )}
        </div>
      ),
      action: () => notifyPropertyOwner("Arrived room"),
    },
    {
      title: "Left room",
      icon: <CardIconSvg className="inline-block mr-2 pr-[1px] h-4" />,
      suffixIcon: (
        <div
          className={`rounded-full transition duration-500 ease-in-out h-5 w-5 border-2 ${
            data.acf.schedule_notify !== "" &&
            data.acf.schedule_notify === "Left room"
              ? `bg-primary border-primary`
              : `border-secondary border-opacity-30 bg-[#E9F2FF]`
          } text-white text-center flex items-center justify-center`}
        >
          {data.acf.schedule_notify !== "" &&
            data.acf.schedule_notify === "Left room" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="w-3 h-3"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
              </svg>
            )}
        </div>
      ),
      action: () => notifyPropertyOwner("Left room"),
    },
  ];

  const schedule_property_owner_email = CryptoJS.AES.decrypt(
    data.acf.schedule_property_owner?.hash,
    /* eslint-disable */
    process.env.REACT_APP_HASH_KEY,
    /* eslint-enable */
    { format: CryptoJSAesJson }
  )
    .toString(CryptoJS.enc.Utf8)
    .replaceAll('"', "");

  const token = JSON.parse(localStorage.getItem("user"))?.token;
  const email = JSON.parse(localStorage.getItem("user"))?.user?.user_email;

  const schedule_creator_email = CryptoJS.AES.decrypt(
    data.acf.schedule_created_by?.hash,
    /* eslint-disable */
    process.env.REACT_APP_HASH_KEY,
    /* eslint-enable */
    { format: CryptoJSAesJson }
  )
    .toString(CryptoJS.enc.Utf8)
    .replaceAll('"', "");

  const notifyPropertyOwner = async (msg) => {
    try {
      if (schedule_creator_email !== email) {
        setDialogData({
          title: "An Error Occured!",
          subtitle:
            "Whoops! It looks like you're trying to close a live scheduling that you didn't initiate. Sorry, but only the person who started the live scheduling can close it.",
          svgComp: <ErrorSVG className="h-[70px]" />,
        });
        setErrorDialog(true);
        return;
      }
      if (loading) {
        return;
      }
      setLoading(true);
      setLoader(true);
      await sendNotification(
        schedule_property_owner_email,
        `${data.acf.schedule_created_by.full_name} just ${msg.toLowerCase()}`
      );
      const transactionFormData = new FormData();
      transactionFormData.append("id", data.id);
      transactionFormData.append("schedule_notify", msg);
      transactionFormData.append("user_email", email);
      await updateScheduleRequest(token, transactionFormData);
      setLoader(false);
      setLoading(false);
      requestHistory();
    } catch (e) {
      console.log(e)
    }
  };

  const clickStepCard = (step) => {
    if (schedule_creator_email !== email) {
      setDialogData({
        title: "An Error Occured!",
        subtitle:
          "Whoops! It looks like you're trying to close a live scheduling that you didn't initiate. Sorry, but only the person who started the live scheduling can close it.",
        svgComp: <ErrorSVG className="h-[70px]" />,
      });
      setErrorDialog(true);
      return;
    }
    setStepToUpdate(step);
    setOpenStep(true);
  };

  const steps = () => {
    if (data.acf.schedule_step != null) {
      if (data.acf.schedule_step === "Completed") {
        return 4;
      } else if (data.acf.schedule_step === "Done Inspecting") {
        return 3;
      } else if (data.acf.schedule_step === "Inspecting") {
        return 2;
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  };

  return (
    <div className="bg-white w-full">
      <div
        className="table-header flex font-light gap-5 text-[14px] w-full py-4 cursor-pointer"
        onClick={() => setExpand(!expand)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setExpand(!expand);
          }
        }}
        tabIndex={0}
        role="button"
      >
        <div className="w-[30%]">
          <AlignedImageBox2
            profileImage={
              data.acf.schedule_property.gallery != null
                ? data.acf.schedule_property.gallery[0].full_image_url
                : null
            }
            text={data.acf.schedule_property.core.post_title}
            subtext={" "}
          />
        </div>
        <div className="w-[15%] flex items-center justify-start">
          {data.acf.schedule_date}
        </div>
        <div className="w-[15%] flex items-center justify-start">
          {data.acf.schedule_time}
        </div>

        <div className="w-[20%] flex items-center justify-center text-center">
          <span
            className={`py-1 px-4 rounded-2xl text-sm font-normal bg-opacity-20`}
            style={{
              color: `${statusColor}`,
              backgroundColor: `${bgStatusColor}`,
            }}
          >
            {data.acf.schedule_status}
          </span>
        </div>
        <div className="w-[20%] flex items-center justify-center">
          <ImageDropdown
            panelElement={<ProfileDropdownMenu menuItemList={ItemList} />}
            image={{
              src: moreIcon,
              size: 24,
            }}
            nocaretIcon={" "}
            appendClass={"-translate-x-[28%] -mt-[2px]"}
          />
        </div>
      </div>
      {expand && (
        <div className="flex flex-col w-full">
          <div className="flex flex-col md:flex-row md:items-center w-full">
            <div className="flex flex-row md:flex-col md:space-y-2 space-x-4 md:space-x-0 my-4 md:my-0 items-center text-white relative">
              <div
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 ${
                  steps() >= 1
                    ? `bg-secondary`
                    : `bg-[#E9F2FF] border-opacity-30`
                } border-secondary text-white text-center flex items-center justify-center`}
              >
                {steps() >= 1 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                )}
              </div>
              <div className="md:hidden border-gray-500 rounded-md border px-4 py-2 text-[#656565]">
                <h4 className="text-[14px] font-medium">Scheduled</h4>
                <p className="text-[10px]">
                  Inspection scheduled for {data.acf.schedule_date},{" "}
                  {data.acf.schedule_time}.
                </p>
              </div>
            </div>

            <div className="flex-auto border-l-2 md:border-t-2 border-dashed transition duration-500 ease-in-out border-secondary"></div>
            <div className="flex flex-row md:flex-col md:space-y-2 space-x-4 md:space-x-0 my-4 md:my-0 items-center text-gray-500 relative ">
              <div
                onClick={() => clickStepCard("Inspecting")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    clickStepCard("Inspecting");
                  }
                }}
                tabIndex={0}
                role="button"
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 ${
                  steps() >= 2
                    ? `bg-secondary`
                    : `bg-[#E9F2FF] border-opacity-30`
                } border-secondary text-white text-center flex items-center justify-center cursor-pointer`}
              >
                {steps() >= 2 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                )}
              </div>
              <div className="md:hidden border-gray-500 rounded-md border px-4 py-2 text-[#656565]">
                <h4 className="text-[14px] font-medium">Inspecting</h4>
                <p className="text-[10px]">
                  Inspection scheduled for {data.acf.schedule_date},{" "}
                  {data.acf.schedule_time}.
                </p>
              </div>
            </div>
            <div className="flex-auto border-l-2 md:border-t-2 border-dashed transition duration-500 ease-in-out border-secondary"></div>
            <div className="flex flex-row md:flex-col md:space-y-2 space-x-4 md:space-x-0 my-4 md:my-0 items-center text-gray-500 relative">
              <div
                onClick={() => clickStepCard("Done Inspecting")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    clickStepCard("Done Inspecting");
                  }
                }}
                tabIndex={0}
                role="button"
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 ${
                  steps() >= 3
                    ? `bg-secondary`
                    : `bg-[#E9F2FF] border-opacity-30`
                } border-secondary text-white text-center flex items-center justify-center cursor-pointer`}
              >
                {steps() >= 3 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                )}
              </div>
              <div className="md:hidden border-gray-500 rounded-md border px-4 py-2 text-[#656565]">
                <h4 className="text-[14px] font-medium">Done Inspecting</h4>
                <p className="text-[10px]">
                  Inspection scheduled for {data.acf.schedule_date},{" "}
                  {data.acf.schedule_time}.
                </p>
              </div>
            </div>
            <div className="flex-auto border-l-2 md:border-t-2 transition duration-500 ease-in-out border-secondary border-dashed"></div>
            <div className="flex flex-row md:flex-col md:space-y-2 space-x-4 md:space-x-0 my-4 md:my-0 items-center text-gray-500 relative">
              <div
                onClick={() => clickStepCard("Completed")}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    clickStepCard("Completed");
                  }
                }}
                tabIndex={0}
                role="button"
                className={`rounded-full transition duration-500 ease-in-out h-12 w-12 py-3 border-2 ${
                  steps() === 4
                    ? `bg-secondary`
                    : `bg-[#E9F2FF] border-opacity-30`
                } border-secondary text-white text-center flex items-center justify-center cursor-pointer`}
              >
                {steps() === 4 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4.5 12.75l6 6 9-13.5"
                    />
                  </svg>
                )}
              </div>
              <div className="md:hidden px-4 py-2 text-[#656565] flex items-end justify-end">
                <h4 className="text-[14px] font-medium">Completed</h4>
              </div>
            </div>
          </div>
          <div className="hidden my-5 md:grid grid-cols-4 gap-10 justify-between w-full">
            <div className=" border-gray-500 rounded-md border px-4 py-2 text-[#656565]">
              <h4 className="text-[14px] font-medium">Scheduled</h4>
              <p className="text-[10px]">
                Inspection scheduled for {data.acf.schedule_date},{" "}
                {data.acf.schedule_time}.
              </p>
            </div>
            <div className=" border-gray-500 rounded-md border px-4 py-2 text-[#656565]">
              <h4 className="text-[14px] font-medium">Inspecting</h4>
              <p className="text-[10px]">
                Inspection scheduled for {data.acf.schedule_date},{" "}
                {data.acf.schedule_time}.
              </p>
            </div>
            <div className=" border-gray-500 rounded-md border px-4 py-2 text-[#656565]">
              <h4 className="text-[14px] font-medium">Done Inspecting</h4>
              <p className="text-[10px]">
                Inspection scheduled for {data.acf.schedule_date},{" "}
                {data.acf.schedule_time}.
              </p>
            </div>
            <div className="px-4 py-2 text-[#656565] flex items-end justify-end">
              <h4 className="text-[14px] font-medium">Completed</h4>
            </div>
          </div>
        </div>
      )}
      {openStep && (
        <ChangeScheduleStepModal
          state={openStep}
          property={data}
          step={stepToUpdate}
          status={null}
          action={() => {
            requestHistory();
          }}
          closeModal={() => setOpenStep(false)}
        />
      )}

      <GenericModal
        onClick={() => {
          setErrorDialog(false);
        }}
        modalState={errorDialog}
      >
        <div className="p-[54px] justify-center items-center text-center">
          <button
            onClick={() => {
              setErrorDialog(false);
            }}
            className={
              "close-modal py-[5px] px-3 rounded-full border border-black absolute top-3 right-3"
            }
          >
            X
          </button>
          <div className="flex flex-col gap-5 justify-center items-center">
            {dialogData?.svgComp}
            {dialogData?.title && (
              <h3 className="text-bold text-[20px] header-font">
                {dialogData?.title}
              </h3>
            )}
            {dialogData?.subtitle && (
              <p className="font-light text-strong-[200] text-md mb-4">
                {dialogData?.subtitle}
              </p>
            )}
          </div>
        </div>
      </GenericModal>
    </div>
  );
}

ScheduleCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    acf: PropTypes.shape({
      schedule_status: PropTypes.string.isRequired,
      schedule_notify: PropTypes.string,
      schedule_property_owner: PropTypes.shape({
        hash: PropTypes.string.isRequired,
      }).isRequired,
      schedule_created_by: PropTypes.shape({
        hash: PropTypes.string.isRequired,
        full_name: PropTypes.string.isRequired,
      }).isRequired,
      schedule_step: PropTypes.string,
      schedule_property: PropTypes.shape({
        gallery: PropTypes.arrayOf(
          PropTypes.shape({
            full_image_url: PropTypes.string.isRequired,
          })
        ),
        core: PropTypes.shape({
          post_title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      schedule_date: PropTypes.string.isRequired,
      schedule_time: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  setLoader: PropTypes.func.isRequired,
  requestHistory: PropTypes.func.isRequired,
};
