

const TermsConditions = () => {
    document.title = 'Terms & Conditions';
    return(
        <div className="w-full font-light text-stone-500 px-5">
            <p className="mb-4">
            Lörem ipsum plade tonas och geosm serat autotongen tredösade eftersom modemkapare dinar i gåplalanade så syss, kalur manar. Förade trebel laheten, desm än karende, dere läpösk fast anteliga inte slidkrans spikmatta oktig soligt. Bejurat dertad: har din garanterad traditionell specialitet i lall onade för att kulig. Solagt suprande alkobom hemilånar, entourage pögt, olig, autoda resening om pans. Diabill kav negisk böligen oaktat biktig för att lesebuv.
            </p>
            <p className="mb-4">
            Lärar decint plus sar våska dodat för att pararade och vinterkräksjuka dina disengar, treras om än odade dohyrat barat oligt: i prodade, vås i odovis mis. Nelänas dins. Myr irade det prelingar och hypoboderar i flyttstajla povis, intramin och basper dedorade, och diskapet jurade, podektig, kanera jugen fastän obel vinde nelanade dilagen. Div dire Maudeffekten, netining plabin nibil att deminetik semivare vöde, prev att kabän cykelbarometer digång ifall tos men blippbetalning gågåliga, i sora. Vilopension bobunar åst liktig.
            </p>
            <p className="mb-4">
            Ter multir kavårar lönat våbabelt eftersom trollfilter. Doförar neling även om våsk förutom nyre paver förutom syligen tretening inte visedat förutom polykar och homov kanade att vining, binde, monodöna i bedat hybusat. Multiligen vebonat, nys lera olenat grit. Tivirade vara att sar: apotion, i pseudorad. Pok ärad åmest i nymast.
            </p>
            <p className="mb-4">
            Iv täng sedan lihyskade, sotilig att trek för mykroska i sagon. Diment homomysade teraskade sans tist kuktig eusade mar potrer, sora börsrobot ultranas för ar. Belument sanningsbarometer fast ed att dispede gigavide kasade monon har cirkulär ekonomi att trada förutom obude vaveska, tävla ut ponde, pötäbel oaktat niliga de trement.
            </p>
        </div>
    );
}

export default TermsConditions;