import PropTypes from "prop-types";
import Moment from "react-moment";
import profileImage from "../../../assets/images/user.png";

const User = ({ currentUser, chatUser, selectUser, chat, data }) => {
  return (
    <>
      <button
        className={
          "chatuser-card w-full p-3 flex justify-between hover:bg-neutral-100 rounded-sm " +
          (chat.uid === chatUser.uid ? "bg-neutral-100" : "")
        }
        onClick={() => selectUser(chatUser)}
      >
        <div className="w-[41px] mr-3">
          <img
            className=" w-[41px] h-[41px] rounded-full"
            src={chatUser.profile_photo || profileImage}
            alt="avatar"
          />
        </div>
        <div className="w-[90%] flex flex-row justify-between items-center gap-1 pb-4 border-b border-b-neutral-200">
          <div className="flex flex-col text-left">
            <div className="block">
              <span className="header-font font-semibold text-[16px] pr-2">
                {chatUser?.first_name + " " + chatUser?.last_name}
              </span>
            </div>
            <p className="text-neutral-700 font-light text-[14px]">
              <strong>{data?.from === currentUser ? "Me: " : null}</strong>
              {data?.text || "No message sent yet!"}
            </p>
          </div>
          <div className="flex flex-col gap-3 pb-4 items-end">
            <span className="text-secondary text-[12px]">
              <Moment fromNow>{data?.createdAt?.toDate()}</Moment>
            </span>

            {data?.from !== currentUser && data?.unread && (
              <span className="text-[12px] rounded-md bg-secondary text-white px-[6px] py-[1px]">
                new
              </span>
            )}
          </div>
        </div>
      </button>
    </>
  );
};

User.propTypes = {
  currentUser: PropTypes.string.isRequired,
  chatUser: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    profile_photo: PropTypes.string,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  selectUser: PropTypes.func.isRequired,
  chat: PropTypes.shape({
    uid: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    from: PropTypes.string.isRequired,
    text: PropTypes.string,
    createdAt: PropTypes.shape({
      toDate: PropTypes.func.isRequired,
    }).isRequired,
    unread: PropTypes.bool.isRequired,
  }).isRequired,
};

export default User;
