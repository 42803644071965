import PropTypes from "prop-types";
import image from "../../../assets/images/user.png";
import { ReactComponent as CheckedSVG } from "../../../assets/vectors/Groupchecked.svg";

const AlignedImageBox = ({ profileImage, text, subtext, check }) => {
  return (
    <div className="flex items-center mb-1">
      <img
        className="w-[37px] h-[37px] md:h-[50px] md:w-[50px] rounded-full"
        src={profileImage ? profileImage : image}
        alt=""
        height="50"
        width="50"
      />
      <div className="flex flex-col ml-3">
        <div className="flex gap-2">
          <span className="md:font-semibold text-sm md:text-md">
            {text ? text : ""}
          </span>
          {check && <CheckedSVG className="w-4 h-4 mt-[3px] md:w-5 md:h-5" />}
        </div>
        <p className="text-xs md:text-sm lowercase text-stone-400 font-light mt-0 md:mt-0">
          {subtext ? subtext : "@teemah"}
        </p>
      </div>
    </div>
  );
};

AlignedImageBox.propTypes = {
  profileImage: PropTypes.string, 
  text: PropTypes.string.isRequired, 
  subtext: PropTypes.string.isRequired, 
  check: PropTypes.bool,
};

export default AlignedImageBox;
