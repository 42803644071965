import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  addDoc,
  Timestamp,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDNrMaDvTBcVc-g6TSB5m8emotXPz3KLQ4",
  authDomain: "rummies-dev.firebaseapp.com",
  databaseURL: "https://rummies-dev-default-rtdb.firebaseio.com",
  projectId: "rummies-dev",
  storageBucket: "rummies-dev.appspot.com",
  messagingSenderId: "134071371000",
  appId: "1:134071371000:web:02960ce18ae5bed5b7e70d"
};

const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const auth = getAuth(firebaseApp);

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

export const signInWithGooglePopup = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);

    return result;
  } catch (error) {
    // console.error("Error during Google Sign-In:", error);
  }
};

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd,
  // field
) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });

  await batch.commit();
  console.log("done");
};

export const getCategoriesAndDocuments = async () => {
  const collectionRef = collection(db, "categories");
  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);
  const categoryMap = querySnapshot.docs.reduce((acc, docSnapshot) => {
    const { title, items } = docSnapshot.data();
    acc[title.toLowerCase()] = items;
    return acc;
  }, {});

  return categoryMap;
};

export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  if (!userAuth) return;

  const userDocRef = doc(db, "users", userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { email } = userAuth;
    const createdAt = new Date();
    const uid = userAuth.uid;

    try {
      await setDoc(userDocRef, {
        email,
        createdAt,
        uid,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error creating the user", error.message);
    }
  } else {
    //if uid doesn't exist in user
    if (!userSnapshot.get("uid")) {
      let uid = userAuth.uid;
      await setDoc(
        userDocRef,
        {
          uid,
        },
        { merge: true }
      );
    }

    if (!userSnapshot.get("email")) {
      let { email } = userAuth;
      let createdAt = new Date();
      await setDoc(
        userDocRef,
        {
          email,
          createdAt,
        },
        { merge: true }
      );
    }

    //if avatar doesn't exist for user
    if (additionalInformation) {
      await setDoc(
        userDocRef,
        {
          ...additionalInformation,
        },
        { merge: true }
      );
    }
  }

  return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const sendFirebasePasswordReset = async (email, id, key) => {
  if (!email) return;

  const actionCodeSettings = {
    url: `${
      /* eslint-disable */
      process.env.VITE_PUBLIC_URL
      /* eslint-enable */
    }/password-reset?key=${key}&wpId=${id}&email=${email}&wpCode=${key}`,
    handleCodeInApp: true,
  };

  return await sendPasswordResetEmail(auth, email, actionCodeSettings);
};

export const confirmFirebaseNewPassword = async (code, password) => {
  if (!code || !password) return;

  return await confirmPasswordReset(auth, code, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const sendNotification = async (email, message) => {
  const userRef = collection(db, "users");
  const notificationRef = collection(db, "notifications");

  const qu = query(userRef, where("email", "==", email));
  const snapShot = await getDocs(qu);
  if (snapShot.docs.length > 0) {
    await addDoc(notificationRef, {
      userId: snapShot.docs[0].id,
      message: message,
      unread: true,
      createdAt: Timestamp.fromDate(new Date()),
    });
  }
};

export const markNotificationAsRead = async (uid) => {
  const notificationRef = collection(db, "notifications");

  const q = query(
    notificationRef,
    where("userId", "==", uid),
    orderBy("createdAt", "desc")
  );

  onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach(async (doc) => {
      const docRef = doc(db, "notifications", doc.id);

      await setDoc(docRef, { unread: false });
    });
  });
};

export const sendScheduleMessage = async (
  email,
  myUid,
  date,
  time,
  scheduleId
) => {
  const usersRef = collection(db, "users");

  const qu = query(usersRef, where("email", "==", email));
  const snapShot = await getDocs(qu);
  if (snapShot.docs.length > 0) {
    let chatUserId = snapShot.docs[0].data().uid;
    const id =
      myUid > chatUserId ? `${myUid + chatUserId}` : `${chatUserId + myUid}`;

    const users_engaged =
      myUid > chatUserId ? [myUid, chatUserId] : [chatUserId, myUid];

    await addDoc(collection(db, "messages", id, "chat"), {
      text: "",
      isSchedule: true,
      scheduleDate: date,
      scheduleTime: time,
      from: myUid,
      isConfirmed: false,
      scheduleId: scheduleId,
      schedulePropertyOwnerId: chatUserId,
      to: chatUserId,
      createdAt: Timestamp.fromDate(new Date()),
      media: "",
    });

    await setDoc(doc(db, "lastMsg", id), {
      isSchedule: true,
      text: "",
      from: myUid,
      to: chatUserId,
      createdAt: Timestamp.fromDate(new Date()),
      media: "",
      unread: true,
      users_engaged,
    });
  }
};


