import { ReactComponent as ScheduleSVG } from "../../../assets/vectors/location.svg";

const NoScheduleScreen = () => {
  return (
    <>
      <div className="flex flex-col justify-center items-center h-[80vh] gap-4 no-live-schedule">
        <ScheduleSVG className="h-[200px] w-[200px] " />
        <h4 className="font-semibold text-lg text-center header-font">
          No live schedules found.
        </h4>
        <p className="text-center text-sm text-strong-100 font-light">
          To schedule a live event, please click on the &apos;Schedule a Live
          Event&apos; button in the top right corner of the page. From there,
          you can set the date, time, and other details for your event. Once you
          have filled out the necessary information, click &apos;Create
          Event&apos; to make it live on the schedule.
        </p>
      </div>
    </>
  );
};

export default NoScheduleScreen;
