import React from "react";
import PropTypes from "prop-types";
import emptySearchVideo from "../../assets/videos/87830-empty-search.mp4";

const NotFoundRender = ({ message }) => {
  return (
    <div className="flex justify-center">
      <div className="w-[500px] h-[500px] bg-red-200 rounded-full flex justify-center items-center">
        <div className="shadow-lg w-[400px] h-[400px] bg-white flex flex-col justify-center items-center gap-3">
          <video loop autoPlay style={{ height: "210px", width: "210px" }}>
            <source
              width={"80px"}
              height={"80px"}
              src={emptySearchVideo}
              type="video/mp4"
            />
            <track kind="captions" label="No captions available" />
          </video>
          <h2 className="text-2xl center font-semibold">
            Sorry No Results Found :(
          </h2>
          <p className="font-light">{message}</p>
        </div>
      </div>
    </div>
  );
};

NotFoundRender.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NotFoundRender;
