import PropTypes from "prop-types";
import image from "../../../assets/images/image-1.png";

const SwitchRoleBox = ({ imageSrc, text, description, ...params }) => {
  return (
    <button {...params}>
      <div
        className="p-3 flex flex-row bg-white border-solid border rounded-md h-max-[148px] w-max-[555px] hover:border-secondary space-x-6"
        style={{
          boxShadow: "0px 6px 20px -4px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="rounded-md border border-secondary h-[80px] w-[80px] p-2">
          <img
            src={imageSrc ? imageSrc : image}
            className="h-full w-full"
            alt="rummate-option"
          />
        </div>

        <div className="flex flex-col items-start w-2/3">
          <h4 className="text-[20px] font-medium">
            {text ? text : "I need a rummate"}
          </h4>
          <p className="text-left lg:text-justify text-[16px]">
            {description ? description : "I need a rummate"}
          </p>
        </div>
      </div>
    </button>
  );
};

SwitchRoleBox.propTypes = {
  imageSrc: PropTypes.string,
  text: PropTypes.string, 
  description: PropTypes.string, 
};

export default SwitchRoleBox;
