import PropTypes from "prop-types";
import * as React from "react";
import {
  animate,
  // AnimationOptions,
  // motion,
  // MotionStyle,
  // PanInfo,
  useMotionValue,
} from "framer-motion";
// import Arrow from "./arrow";
import Dots from "./dots";
import { useRef } from "react";
import Slider from "./slider";

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
  overflowX: "hidden",
  display: "flex",
};

const transition = {
  type: "spring",
  bounce: 0,
};

const Contaier = ({ innerRef, children }) => (
  <div ref={innerRef} style={containerStyle}>
    {children}
  </div>
);
Contaier.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  children: PropTypes.node.isRequired,
};

export const Carousel = ({
  children,
  // renderArrowLeft,
  // renderArrowRight,
  renderDots,
  autoPlay = true,
  interval = 2000,
  loop = true,
}) => {
  const x = useMotionValue(0);
  const containerRef = useRef(null);
  const [index, setIndex] = React.useState(0);

  const calculateNewX = () => -index * (containerRef.current?.clientWidth || 0);

  const handleEndDrag = (e, dragProps) => {
    const clientWidth = containerRef.current?.clientWidth || 0;

    const { offset } = dragProps;

    if (offset.x > clientWidth / 4) {
      handlePrev();
    } else if (offset.x < -clientWidth / 4) {
      handleNext();
    } else {
      animate(x, calculateNewX(), transition);
    }
  };

   const handleNext = React.useCallback(() => {
     const idx = loop ? 0 : index;
     setIndex(index + 1 === children.length ? idx : index + 1);
   }, [index, loop, children.length]);

   const handlePrev = React.useCallback(() => {
     const idx = loop ? children.length - 1 : 0;
     setIndex(index - 1 < 0 ? idx : index - 1);
   }, [index, loop, children.length]);

  React.useEffect(() => {
    const controls = animate(x, calculateNewX(), transition);
    return controls.stop;
  }, [index]);

  React.useEffect(() => {
    if (!autoPlay) {
      return;
    }
    const timer = setInterval(() => handleNext(), interval);
    return () => clearInterval(timer);
  }, [handleNext, interval]);

  return (
    <Contaier innerRef={containerRef}>
      {children.map((child, i) => (
        <Slider onDragEnd={handleEndDrag} x={x} i={i} key={i}>
          {child}
        </Slider>
      ))}
      {/* left arrow */}
      {/* {renderArrowLeft ? (
        renderArrowLeft({ handlePrev, activeIndex: index })
      ) : (
        <Arrow left onClick={handlePrev}>
          &larr;
        </Arrow>
      )} */}

      {/* right arrow */}
      {/* {renderArrowRight ? (
        renderArrowRight({ handleNext, activeIndex: index })
      ) : (
        <Arrow onClick={handleNext}>&rarr;</Arrow>
      )} */}

      {/* dots */}
      {renderDots ? (
        renderDots({ setActiveIndex: setIndex, activeIndex: index })
      ) : (
        <Dots
          length={children.length}
          setActiveIndex={setIndex}
          activeIndex={index}
        />
      )}
    </Contaier>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  renderDots: PropTypes.func,
  autoPlay: PropTypes.bool,
  interval: PropTypes.number,
  loop: PropTypes.bool,
};
