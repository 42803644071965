import { Link } from 'react-router-dom';
import {ReactComponent as EditSVG} from '../../../assets/vectors/edit.svg'

const NoRequestsScreen = () => {
    return(
        <div className='flex flex-col justify-center items-center h-[80vh] gap-4'>
            <EditSVG className="h-[200px] w-[200px]" />
            <h4 className="font-semibold text-lg text-center header-font" >No property created yet!</h4>
            <p className="text-center text-sm text-strong-100 font-light">Get your account verified and make a request post</p>
            <Link to="../my-account/?action=verify_account">
                <button className='w-[293px] mt-5 border border-stone-400 rounded-md p-4 bg-white hover:bg-primary hover:text-white hover:border-primary'>Verify identity</button>
            </Link>
        </div>
    );
}

export default NoRequestsScreen;