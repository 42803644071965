import PropTypes from 'prop-types';
import { useContext, useState } from "react";
import { updateWpUser } from "../../../api/wordpress";
import image from "../../../assets/images/Frame-3.png";
import { UserContext } from "../../../contexts/user.context";
import GenericModal from "../../modals/generic-modal.component";
import { IoIosArrowRoundBack } from "react-icons/io";
import videoSrc from "../../../assets/videos/104369-check-motion.mp4";



const ProgressBar = ({ completed }) => {
  return (
    <div
      data-testid="progress-bar"
      className="h-[20px] w-full bg-[#e0e0de] rounded-lg my-5"
    >
      <div
        className={`h-full text-right rounded-lg bg-secondary`}
        style={{ transition: "width 1s ease-in-out", width: `${completed}%` }}
      >
        <span className="text-white text-[8px] align-middle inline pb-3 pr-2">{`${completed}%`}</span>
      </div>
    </div>
  );
};


ProgressBar.propTypes = {
  completed: PropTypes.number.isRequired,
};

const defaultErrorObject = {
  code: "",
  status: false,
  message: "",
};


const VerifyUserForm = ({ resetPage }) => {
  const [completed, setCompleted] = useState(0);
  const { persistUpdatedUser, accessToken, user } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [errorObj, setLoginError] = useState(defaultErrorObject);

  const handleChange = async (event) => {
    const { name } = event.target;
    if (name === "verify_doc") {
      const file = event.target.files[0];

      setLoading(true);
      const formData = new FormData();
      formData.append("user_email", user.user_email);
      formData.append("file_key", "verify_doc");
      formData.append("file", file);

      try {
        const response = await updateWpUser(
          accessToken,
          formData,
          (progressEvent) => {
            const progress = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
            setCompleted(progress);
          }
        );
        persistUpdatedUser(response.data);
        setModalState(true);
      } catch (error) {
        const { code, message } = error.response.data
          ? error.response.data
          : error;

        setLoginError({
          code: code,
          message: message,
          status: true,
        });
      }

      setCompleted(0);
      setLoading(false);
    }
  };

  return (
    <>
      <form id="verify-user_form">
        <p className="text-[12px] font-light mt-2 mb-[40px]">
          Upload any government-approved ID card to get your account verified.
        </p>
        <button
          type="button"
          className="text-[12px] flex gap-2 font-light items-center mb-2 cursor-pointer"
          onClick={() => resetPage("")}
        >
          <IoIosArrowRoundBack /> Go Back
        </button>
        <button
          type="button"
          data-testid="verify_doc"
          onClick={() => {
            if (!loading) document.getElementById("verify_doc").click();
          }}
          className="cursor-pointer p-0 border-none bg-transparent"
        >
          <img src={image} alt="input-field" className="cursor-pointer" />
        </button>
        <input
          id="verify_doc"
          name="verify_doc"
          className="hidden"
          type="file"
          onChange={handleChange}
        />
        <button type="submit" className="hidden" id="submit_ver_file"></button>
        {loading && (
          <ProgressBar  completed={completed} />
        )}
        {errorObj.status && (
          <div
            className="flex p-4 mt-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div dangerouslySetInnerHTML={{ __html: errorObj.message }} />
          </div>
        )}
        <GenericModal
          onClick={(event) => {
            if (event.target.classList.contains("close-modal")) {
              setModalState(false);
              resetPage("");
            }
          }}
          modalState={modalState}
        >
          <div className="m-10 flex flex-col gap-5 text-center">
            <video loop autoPlay>
              <source
                width={"140px"}
                height={"140px"}
                src={videoSrc}
                type="video/mp4"
              />
              <track kind="captions" src="" label="No captions available" />
            </video>
            Your Verification Document is uploaded!
            <button
              onClick={() => {
                setModalState(false);
                resetPage("");
              }}
              className="close-modal w-full rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer"
            >
              Ok
            </button>
          </div>
        </GenericModal>
      </form>
    </>
  );
};

VerifyUserForm.propTypes = {
  resetPage: PropTypes.func.isRequired,
};

export default VerifyUserForm;
