import { updateWpUser } from "../../../api/wordpress";
import InputField from "../../fields/input-field/form-input.component";
import { useState, useContext } from "react";
import { UserContext } from "../../../contexts/user.context";
import SuccessModal from "../../modals/success-modal.component";
import { ReactComponent as EditSvg } from "../../../assets/vectors/edit.svg";
import image from "../../../assets/images/user.png";

const defaultErrorObject = {
  code: "",
  status: false,
  message: "",
};

const EditProfileForm = () => {
  const { persistUpdatedUser, accessToken, user } = useContext(UserContext);
  const [displayPhoto, setDisplayPhoto] = useState(
    user?.profile_photo || image
  );

  const defaultFormFields = {
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    user_email: user?.user_email || "",
    phone_number: user?.phone_number || "",
    bio: user?.bio || "",
    // maximum_amount_per_year: user?.maximum_amount_per_year ?? "", // Remove or uncomment as needed
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const {
    first_name,
    last_name,
    user_email,
    phone_number,
    // maximum_amount_per_year, // Remove or uncomment as needed
    bio,
  } = formFields;

  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [errorObj, setLoginError] = useState(defaultErrorObject);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "profile_photo") {
      const file = event.target.files[0];
      const imagePreview = document.querySelector("#profile-image-preview");
      if (file) {
        imagePreview.src = URL.createObjectURL(file);
        setDisplayPhoto(imagePreview.src);
      }
      setFormFields({ ...formFields, [name]: file });
    } else {
      setFormFields({ ...formFields, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLoginError(defaultErrorObject);

    const formData = new FormData(event.target);
    formData.append("user_email", user_email);
    if (formFields.profile_photo) {
      formData.append("file_key", "profile_photo");
      formData.append("file", formFields.profile_photo);
    }

    try {
      const response = await updateWpUser(accessToken, formData);
      persistUpdatedUser(response.data);
      setModalState(true);
    } catch (error) {
      setLoading(false);
      const { code, message } = error.response.data
        ? error.response.data
        : error;

      setLoginError({
        code: code,
        message: message,
        status: true,
      });
      setModalState(true);
    }
    setLoading(false);
  };

  return (
    <form
      id="edit-profile_form"
      className="font-light w-full"
      onSubmit={handleSubmit}
    >
      {errorObj.status && (
        <div
          className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
          role="alert"
        >
          <svg
            className="inline flex-shrink-0 mr-3 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
              clipRule="evenodd"
            ></path>
          </svg>
          <div dangerouslySetInnerHTML={{ __html: errorObj.message }} />
        </div>
      )}
      <div className="mb-10 flex justify-center">
        <span
          role="button"
          tabIndex={0}
          onClick={() => {
            document.getElementById("profile_photo").click();
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              document.getElementById("profile_photo").click();
            }
          }}
        >
          <img
            id="profile-image-preview"
            className="p-1 shadow-md cursor-pointer h-[100px] w-[100px] rounded-full object-cover"
            src={displayPhoto}
            alt="profile-img"
          />
          <div className="rounded-full cursor-pointer bg-secondary h-[24px] w-[24px] ml-[70px] -mt-6 relative">
            <EditSvg className="p-1 svg-icon-white" />
          </div>
        </span>
      </div>
      <InputField
        label="First Name"
        type="text"
        name="first_name"
        value={first_name}
        required
        onChange={handleChange}
      />
      <InputField
        label="Last Name"
        type="text"
        name="last_name"
        value={last_name}
        required
        onChange={handleChange}
      />
      <InputField
        label="Email"
        type="email"
        name="user_email"
        value={user_email}
        disabled
        required
        onChange={handleChange}
      />
      <InputField
        label="Phone Number"
        type="tel"
        name="phone_number"
        required
        value={phone_number}
        onChange={handleChange}
      />
      {/* <InputField
        label="Rent per year"
        type="number"
        name="maximum_amount_per_year"
        required
        value={maximum_amount_per_year}
        onChange={handleChange}
      /> */}
      <InputField
        label="Bio"
        type="textarea"
        name="bio"
        required
        value={bio}
        onChange={handleChange}
      />
      <input
        id="profile_photo"
        name="profile_photo"
        className="hidden"
        type="file"
        onChange={handleChange}
      />
      <div className="field_wrapper mb-5">
        <button
          type="submit"
          className="w-full rounded-md text-white p-3 bg-primary hover:bg-blue-900 font-semibold cursor-pointer"
        >
          {loading && (
            <svg
              role="status"
              className="inline w-4 h-4 mr-3 text-white animate-spin"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="#E5E7EB"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentColor"
              />
            </svg>
          )}
          Update Profile
        </button>
      </div>
      <SuccessModal
        action={() => {
          setModalState(false);
        }}
        state={modalState}
        title="Successful Profile Update!"
        description="Your profile has been successfully update."
      />
    </form>
  );
};

export default EditProfileForm;
