import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const handleScriptLoad = (
  updateQuery,
  updateAddressObj,
  autoCompleteRef,
  localeTypes
) => {
  const localeOptions = {
    types: ["political", "locality"],
    componentRestrictions: { country: "ng" },
  };
  if (localeTypes) localeOptions["types"] = localeTypes;
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    localeOptions
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery, updateAddressObj)
  );
};

const handlePlaceSelect = async (updateQuery, updateAddressObj) => {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  updateAddressObj(addressObject);
};

const SearchLocationInput = ({
  setAddressObj,
  label,
  defaultValue = "",
  localeTypes,
  placeholder,
  parentClassName,
  ...params
}) => {
  const [query, setQuery] = useState(""); 
  const autoCompleteRef = useRef(null);

  console.log(query)

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${
        /* eslint-disable */
        process.env.REACT_APP_GOOGLE_API_KEY
        /* eslint-enable */
      }&libraries=places&callback=initMap`,
      () =>
        handleScriptLoad(setQuery, setAddressObj, autoCompleteRef, localeTypes)
    );
  }, [localeTypes, setAddressObj]);

  return (
    <div
      className={`search-location-input field_wrapper mb-10 ${
        parentClassName || ""
      }`}
    >
      {label && (
        <label htmlFor="location" className="block text-sm text-gray-600 pb-3">
          {label}
        </label>
      )}
      <input
        id="location"
        ref={autoCompleteRef}
        onChange={(event) => {
          setQuery(event.target.value);
          if (event.target.value === "") setAddressObj(null);
        }}
        placeholder={placeholder || "Enter a City"}
        defaultValue={defaultValue}
        className="w-full rounded-md p-4 border border-gray-300 "
        {...params}
      />
    </div>
  );
};

SearchLocationInput.propTypes = {
  setAddressObj: PropTypes.func.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  localeTypes: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.string,
  parentClassName: PropTypes.string,
};

export default SearchLocationInput;
