import React from "react";
import PropTypes from "prop-types";
import rummiesLoader from "../../assets/images/rummiesloader.gif";

const PageLoader = ({ size, ...params }) => {
  return (
    <div {...params}>
      <img src={rummiesLoader} height={size} width={size} alt="loading..." />
    </div>
  );
};

PageLoader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PageLoader;
