import PropTypes from "prop-types";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardMenu from "../menus/dashboard-menu/menu.component";
import logoRepeat from "../../assets/vectors/logo-repeat.svg";
import { ReactComponent as LOGO } from "../../assets/vectors/ruumies-logo.svg";

// import logo from "../../assets/images/logoimg.png";
import { MdArrowBackIos } from "react-icons/md";

const Sidebar = ({ menuItemList, backButton }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {!showSidebar &&
        (backButton ? (
          <button
            className="absolute left-5 z-10 w-10 h-5 inline-block"
            onClick={() => navigate(-1)}
          >
            <MdArrowBackIos className="relative w-full h-full cursor-pointer" />
          </button>
        ) : (
          <svg
            onClick={() => setShowSidebar(!showSidebar)}
            className={"flex items-center cursor-pointer lg:hidden -mr-1"}
            fill=""
            viewBox="0 0 100 70"
            width="30"
            height="30"
          >
            <rect width="80" height="5"></rect>
            <rect y="20" width="80" height="5"></rect>
            <rect y="40" width="80" height="5"></rect>
          </svg>
        ))}

      {showSidebar && (
        <div className="overlay top-0 left-0 fixed h-full w-full bg-[#1f1f1fa6] z-40 "></div>
      )}
      <div
        className={`lg:hidden top-0 left-0 w-full bg-primary shadow-2xl text-white fixed h-full z-40 ease-in-out duration-300 ${
          showSidebar ? "translate-x-0 " : "-translate-x-full"
        }`}
        style={{ backgroundImage: `url(${logoRepeat})` }}
      >
        <button
          onClick={() => setShowSidebar(false)}
          className="absolute right-8 top-8 p-2 rounded-full w-10 h-10 border border-white text-center"
        >
          X
        </button>
        <div
          className={`${showSidebar ? "w-full" : ""} text-left ml-10  mt-10`}
        >
          <Link to="/">
            <LOGO className="h-24 object-contain w-28 inline-block align-middle" />
          </Link>
        </div>
        <div className="mt-12 ml-10 h-5/6 overflow-y-auto">
          <DashboardMenu
            menuItemList={menuItemList}
            className="gap-1 flex flex-col  text-lg text-white font-light"
            onClick={(event) => {
              if (
                event.target.parentElement.classList.contains(
                  "svg-menu-item"
                ) ||
                event.target.classList.contains("svg-menu-item")
              ) {
                setShowSidebar(!showSidebar);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  menuItemList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.element,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  backButton: PropTypes.bool,
};
export default Sidebar;
