import { Link } from "react-router-dom";
import VerifyEmailForm from '../../components/forms/verify-email/verify-email-form.component'
import logo from "../../assets/images/logoimg.png";

const VerifyEmailScreen  = () => {
    return ( 
        <div className="mt-20 pl-20 pr-60">
            <img src={logo} alt='logo' width="110" height="23" />
            <h2 className="text-3xl mt-10 mb-8 header-font">Verify Email?</h2>
            <VerifyEmailForm  />
            <p className="mt-5 mb-10 text-md text-grey-200">Go back to 
                <Link className="text-secondary" to="/login"> login page?</Link>
            </p>
        </div>
    );
}

export default VerifyEmailScreen;

