import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

const pageStyle = {
  width: "100%",
  height: "100%",
  display: "inline-block",
  flex: "none",
};

const Slider = ({ x, i, onDragEnd, children }) => (
  <motion.div
    style={{
      ...pageStyle,
      x,
      left: `${i * 100}%`,
      right: `${i * 100}%`,
    }}
    drag="x"
    dragElastic={0.3}
    onDragEnd={onDragEnd}
  >
    {children}
  </motion.div>
);

Slider.propTypes = {
  x: PropTypes.object.isRequired, 
  i: PropTypes.number.isRequired, 
  onDragEnd: PropTypes.func.isRequired, 
  children: PropTypes.node.isRequired, 
};

export default Slider;
