import image from "../../../assets/images/Union.png";
import RegularButton from "../../buttons/regular-button/regular-button.component";

const VerificationPromptBox = () => {
  return (
    <div
      className="relative h-[233px] mt-5 pt-7 flex flex-col items-center px-10 gap-3 justify-center"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="absolute top-[8px]">
        <div className="bg-[#FF0000] w-10 h-10 rounded-full text-white text-[30px] text-center flex items-center justify-center">
          !
        </div>
      </div>

      <span className="text-black text-md">Verify Identity</span>
      <p className="text-sm mb-3 text-stone-500 font-light text-center">
        Upload any government approved ID card to get your account verified.
      </p>
      <RegularButton
        exticon={""}
        text={"Verify Identity"}
        linkTo="my-account/?action=verify_account"
      />
    </div>
  );
};

export default VerificationPromptBox;
