import PropTypes from "prop-types";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ReactComponent as DropdownSVG } from "../../../assets/vectors/arrow-drop-down-line.svg";
import profileImage from "../../../assets/images/user.png";
import { ReactComponent as CheckedSVG } from "../../../assets/vectors/verified.svg";

export default function ImageDropdown({
  panelElement,
  image,
  nocaretIcon,
  appendClass,
  check,
  notifyCount,
  ...otherParams
}) {
  return (
    <div className="max-w-sm md:px-2">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                  ${open ? "" : "text-opacity-90"}
                  group inline-flex items-center rounded-md focus:outline-none`}
            >
              <div
                className={`relative w-[${
                  image && image.size ? image.size : "50"
                }px] h-[${image && image.size ? image.size : "50"}px]`}
              >
                <img
                  {...otherParams}
                  src={image && image.src !== "" ? image.src : profileImage}
                  alt=""
                  height={image && image.size ? image.size : "50"}
                  width={image && image.size ? image.size : "50"}
                />
                {notifyCount !== undefined && notifyCount > 0 && (
                  <div className="absolute -bottom-[3px] -right-[3px]">
                    <span className="bg-[#9D0303] w-4 h-4 md:w-[14px] md:h-[14px] rounded-full text-white text-[10px]">
                      {notifyCount}
                    </span>
                  </div>
                )}
                {check && check === true && (
                  <div className="absolute -bottom-[3px] -right-[3px]">
                    <CheckedSVG className="w-4 h-4 mt-[3px] md:w-5 md:h-5" />
                  </div>
                )}
                {check !== undefined && check === false && (
                  <div className="absolute -bottom-[3px] -right-[3px]">
                    <div className="bg-[#9D0303] w-2 h-2 md:w-3 md:h-3 rounded-full"></div>
                  </div>
                )}
              </div>

              {nocaretIcon ? (
                <></>
              ) : (
                <DropdownSVG
                  className={`hidden md:inline ml-2 h-5 w-5`}
                  aria-hidden="true"
                />
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={
                  "absolute z-10 mt-4 w-[250px] md:w-56 md:-translate-x-1/2 transform px-4 sm:px-0 lg:max-w-lg " +
                  appendClass
                }
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-4 bg-white p-4 lg:grid-cols-1">
                    {panelElement}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

ImageDropdown.propTypes = {
  panelElement: PropTypes.node.isRequired, 
  image: PropTypes.shape({
    src: PropTypes.string, 
    size: PropTypes.any
  }),
  nocaretIcon: PropTypes.any, 
  appendClass: PropTypes.string, 
  check: PropTypes.bool, 
  notifyCount: PropTypes.number, 
};
