import PropTypes from "prop-types";
import MyPostRequestCard from "../../card-items/post-request-card-item/my-post-request-card-item.component";
import PostRequestCard from "../../card-items/post-request-card-item/post-request-card-item.component";
import NotFoundRender from "../../info-panes/not-found.component";

const PostRequestCardLoop = ({ requestList, theme, ...params }) => {
  return (
    <>
      {requestList.length > 0 ? (
        <div className="flex flex-col gap-5 md:w-[670px] mb-10" {...params}>
          {requestList.map((item, key) => {
            return theme === 2 ? (
              <PostRequestCard key={key} data={item} />
            ) : (
              <MyPostRequestCard key={key} data={item} />
            );
          })}
        </div>
      ) : (
        <NotFoundRender
          message="No post request was found in this search filter"
          icon=""
          state="error"
        />
      )}
    </>
  );
};

PostRequestCardLoop.propTypes = {
  requestList: PropTypes.arrayOf(PropTypes.object).isRequired, 
  theme: PropTypes.number, 
};

export default PostRequestCardLoop;
