import EditProfileForm from "../../../components/forms/edit-profile/edit-profile-form.component";

const EditProfileScreen = () => {
    return (
        <>
            <h2 className="mb-8 hidden md:block header-font text-[18px] font-normal">Edit Profile</h2>
            <EditProfileForm />
        </>
    );
}

export default EditProfileScreen;