import PropTypes from "prop-types";
import { useState } from "react";

export const PasswordInputField = ({ label, ...otherProps }) => {
  const renderTextarea = otherProps.type === "textarea";
  if (renderTextarea) delete otherProps.type;

  const [showPassword, setShowPassword] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      setShowPassword(!showPassword);
    }
  };

  return (
    <>
      <div className="mt-10 mb-10 relative w-full">
        <input
          {...otherProps}
          id={otherProps.name}
          type={showPassword ? "text" : "password"}
          className={
            "w-full rounded-md p-4 border focus:ring-0 focus:outline-none border-gray-300 " +
            ({ ...otherProps }?.disabled
              ? "disabled:opacity-75 cursor-not-allowed bg-stone-200"
              : "")
          }
          style={{ outlineWidth: ".5px" }}
        />
        {label && (
          <label
            htmlFor={otherProps.name}
            id={otherProps.name}
            className="absolute text-sm text-gray-600 pb-3 -top-7 duration-300 origin-0 left-0"
          >
            {label}
          </label>
        )}
        <div
          role="button"
          aria-label="toggle-password"
          className="absolute inset-y-0 cursor-pointer right-0 pr-3 flex items-center text-icon"
          onClick={() => setShowPassword(!showPassword)}
          onKeyDown={handleKeyDown}
          tabIndex="0"
        >
          {showPassword ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-gray-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-gray-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
};

PasswordInputField.propTypes = {
  label: PropTypes.string,
};

export default PasswordInputField;
